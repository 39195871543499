export const ensureQueryArray = (query: string | string[] | undefined) => {
  // return empty array if query is undefined
  if (!query) return []

  // return array if query is already an array
  if (Array.isArray(query)) return [...query]

  // return array with query as single item
  return [query]
}
