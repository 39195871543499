import { ROUTES } from '.'

export const MENU_ITEMS = [
  {
    name: 'Inspiration',
    linkto: ROUTES.WEDDING_INSPIRATION,
    order: 0,
    active: true,
  },
  {
    name: 'Planning',
    linkto: ROUTES.WEDDING_PLANNING,
    order: 1,
    active: true,
  },
  { name: 'Venues', linkto: ROUTES.VENUE.ROOT, order: 2, active: true },
  {
    name: 'Suppliers',
    linkto: ROUTES.WEDDING_SUPPLIERS.ROOT,
    order: 3,
    active: true,
  },
  { name: 'Recycle', linkto: ROUTES.RECYCLE.HOME, order: 4, active: true },
  {
    name: 'Become Recommended',
    linkto: ROUTES.WEDDING_SUPPLIERS.APPLY.FORM,
    order: 5,
    active: true,
  },
]
