'use client'

import s from './top-menu.module.css'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
// TODO: replace with icon not from antd
import UserOutlined from '@ant-design/icons/UserOutlined'
import { useUser } from '@/hooks/use-user'
import Link from 'next/link'
import { LoggedUserMenu } from '@/v2/components/organisms/navigation/logged-user-right-slider/logged-user-menu'
import { MessageIcon } from '../../../molecules/message-icon/message-icon'

export const V2TopMenu = () => {
  const { user, isAdmin, logOut } = useUser()

  return (
    <div className={s.topBarWrapper}>
      {user ? (
        <div className={s.userItemsWrapper}>
          {!isAdmin && <MessageIcon />}
          <LoggedUserMenu />
          <button className={s.logOutButton} onClick={logOut}>
            Log out
          </button>
        </div>
      ) : (
        <>
          <Link href={'/auth?step=2'} className={s.link}>
            <DownloadOutlined className={s.loginIcon} />
            <span className={s.loginText}>Log In</span>
          </Link>
          <Link href={'/auth?step=1'} className={s.link}>
            <UserOutlined className={s.signInIcon} />
            <span className={s.signUpText}>Sign up</span>
          </Link>
        </>
      )}
    </div>
  )
}
