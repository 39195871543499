type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const ArrowRightShort = ({ iconColor, svgProps }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4C8.27614 4 8.5 4.22386 8.5 4.5V10.2929L10.6464 8.14645C10.8417 7.95118 11.1583 7.95118 11.3536 8.14645C11.5488 8.34171 11.5488 8.65829 11.3536 8.85355L8.35355 11.8536C8.15829 12.0488 7.84171 12.0488 7.64645 11.8536L4.64645 8.85355C4.45118 8.65829 4.45118 8.34171 4.64645 8.14645C4.84171 7.95118 5.15829 7.95118 5.35355 8.14645L7.5 10.2929V4.5C7.5 4.22386 7.72386 4 8 4Z"
        fill={iconColor || '#131313'}
      />
    </svg>
  )
}
