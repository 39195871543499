export const FacebookIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1140_7499)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5 0.5H0.5V30.5H30.5V0.5ZM16.8135 15.5339V23.6947H13.4369V15.5342H11.75V12.7219H13.4369V11.0335C13.4369 8.73925 14.3895 7.375 17.0957 7.375H19.3488V10.1876H17.9405C16.887 10.1876 16.8173 10.5806 16.8173 11.3141L16.8135 12.7216H19.3647L19.0662 15.5339H16.8135Z"
          fill="#3B5999"
        />
      </g>
      <defs>
        <clipPath id="clip0_1140_7499">
          <circle cx="15.5" cy="15.5" r="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
