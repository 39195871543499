'use client'

import { FAVOURITE } from '../../../constants'
import { useRegisterModal } from '../../../hooks/use-register-modal'
import { useUser } from '../../../hooks/use-user'
import { BookmarkIconSVG } from '../../svg/bookmark-icon/bookmark-icon'
import s from './bookmark-icon.module.css'

export interface IBookmarkIconProps {
  itemId: number | string
  type: FAVOURITE
}

export const BookmarkIcon = ({ itemId, type }: IBookmarkIconProps) => {
  const user = useUser()
  const isFavourite = user.hasFavourite(Number(itemId), type)
  const registerModal = useRegisterModal()

  const handleOnClickFavourite = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (user.isReader) {
      user.toggleFavourite(Number(itemId), type)
    } else {
      registerModal.open()
    }
  }

  if (user.isSupplier || user.isAdmin) {
    return null
  }

  return (
    <button className={s.bookmarkButton} onClick={handleOnClickFavourite}>
      <BookmarkIconSVG
        fill={isFavourite ? '#131313' : 'none'}
        className={s.bookmarkIcon}
      />
    </button>
  )
}
