import s from './input.module.css'
import cx from 'classnames'

type Props = {
  forwardRef?: React.Ref<HTMLInputElement>
} & React.InputHTMLAttributes<HTMLInputElement>

export const Input = ({ ...props }: Props) => {
  return (
    <input
      {...props}
      className={cx(s.input, props.className)}
      ref={props.forwardRef}
      id={props.name}
    />
  )
}
