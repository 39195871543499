import cx from 'classnames'
import s from './chevron.module.css'

type Props = React.SVGAttributes<SVGElement> & {
  direction?: 'up' | 'down' | 'left' | 'right'
}

export const Chevron = (props: Props) => {
  const { className, direction = 'left', ...restProps } = props

  const directionClassName = cx({
    [s.up]: direction === 'up',
    [s.down]: direction === 'down',
    [s.left]: direction === 'left',
    [s.right]: direction === 'right',
  })

  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(directionClassName, className)}
      {...restProps}
    >
      <path d="M8.20039 15.5999L9.40039 14.3839L3.01639 7.99989L9.40039 1.61589L8.20039 0.399902L0.60039 7.9999L8.20039 15.5999Z" />
    </svg>
  )
}
