type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const FiltersIconNew = ({ iconColor, svgProps }: Props) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 1C11.2761 1 11.5 1.22386 11.5 1.5V5.5C11.5 5.77614 11.2761 6 11 6C10.7239 6 10.5 5.77614 10.5 5.5V4H2C1.72386 4 1.5 3.77614 1.5 3.5C1.5 3.22386 1.72386 3 2 3H10.5V1.5C10.5 1.22386 10.7239 1 11 1ZM12.5 3.5C12.5 3.22386 12.7239 3 13 3H15C15.2761 3 15.5 3.22386 15.5 3.5C15.5 3.77614 15.2761 4 15 4H13C12.7239 4 12.5 3.77614 12.5 3.5ZM6 5.5C6.27614 5.5 6.5 5.72386 6.5 6V7.5H15C15.2761 7.5 15.5 7.72386 15.5 8C15.5 8.27614 15.2761 8.5 15 8.5H6.5V10C6.5 10.2761 6.27614 10.5 6 10.5C5.72386 10.5 5.5 10.2761 5.5 10V6C5.5 5.72386 5.72386 5.5 6 5.5ZM1.5 8C1.5 7.72386 1.72386 7.5 2 7.5H4C4.27614 7.5 4.5 7.72386 4.5 8C4.5 8.27614 4.27614 8.5 4 8.5H2C1.72386 8.5 1.5 8.27614 1.5 8ZM11 10C11.2761 10 11.5 10.2239 11.5 10.5V14.5C11.5 14.7761 11.2761 15 11 15C10.7239 15 10.5 14.7761 10.5 14.5V13H2C1.72386 13 1.5 12.7761 1.5 12.5C1.5 12.2239 1.72386 12 2 12H10.5V10.5C10.5 10.2239 10.7239 10 11 10ZM12.5 12.5C12.5 12.2239 12.7239 12 13 12H15C15.2761 12 15.5 12.2239 15.5 12.5C15.5 12.7761 15.2761 13 15 13H13C12.7239 13 12.5 12.7761 12.5 12.5Z"
        fill={iconColor || '#fff'}
      />
    </svg>
  )
}
