import cx from 'classnames'
import s from './modal-pane.module.css'
import { useModal } from '@/hooks/use-modal'
import { useRef } from 'react'
import { ModalPositionType } from '@/context/modal-context/types'

type Props = {
  children?: React.ReactNode
  position: ModalPositionType
}

export const ModalPane = (props: Props) => {
  const { children, position } = props
  const modal = useModal()
  const ref = useRef(null)

  const isModalOpeningOrOpen =
    modal.isOpen && !(modal.animationState === 'closing')

  const onClick = (e: React.MouseEvent) => {
    if (e.target === ref.current && isModalOpeningOrOpen) {
      modal.close()
    }
  }

  return (
    <div
      className={cx(s.container, s[position], {
        [s.open]: modal.options.position === position && isModalOpeningOrOpen,
      })}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
