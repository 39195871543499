type Props = React.SVGAttributes<SVGElement>

export const ReadMoreSVG = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="131"
      height="140"
      viewBox="0 0 131 140"
      fill="none"
      {...props}
    >
      <path
        d="M6.63745 105.633C5.8008 105.633 0.948207 105.744 0.501992 105.744C0.0557769 105.744 0.0557769 105.242 0.501992 105.242C1.89641 105.242 2.73307 105.186 3.06773 105.131C3.84861 104.35 4.07171 103.123 4.18327 100.111C4.29482 97.0429 4.3506 91.521 4.3506 88.9553V81.5369C4.3506 67.8158 4.07171 67.5927 1.33865 67.5927H0.334661C-0.111554 67.5927 -0.111554 67.0907 0.334661 67.0907C1.89641 67.0907 5.68924 67.2581 6.5259 67.2581C7.30677 67.2581 12.4382 67.0907 13.3865 67.0907C22.9801 67.0907 27.6653 70.3258 27.6653 76.2381C27.6653 80.9792 21.8088 84.2142 15.7849 85.274C20.5259 85.3855 22.4781 87.1146 26.1036 94.4214C30.7331 104.238 31.7928 105.242 35.1952 105.242H35.5857C36.0877 105.242 36.1434 105.744 35.5857 105.744H33.5777C31.6255 105.744 30.5657 105.967 29.7291 105.967C28.502 105.967 27.7769 105.633 26.8845 104.629C25.7131 103.29 24.1514 100.668 22.255 96.2063C18.0717 86.5569 16.4542 85.776 10.8765 85.6644H8.58964V91.2979C8.58964 105.019 8.86853 105.242 11.6016 105.242H12.7171C13.1633 105.242 13.1633 105.744 12.7171 105.744C11.1554 105.744 7.41833 105.633 6.63745 105.633ZM8.58964 76.015V85.1067C9.6494 85.0509 11.0996 85.0509 13.2749 85.0509C17.5697 85.0509 22.7012 82.5409 22.7012 76.2381C22.7012 70.4373 19.9123 67.5927 12.9402 67.5927H10.7649C8.7012 67.5927 8.58964 67.8158 8.58964 76.015Z"
        fill="white"
      />
      <path
        d="M48.7246 106.302C40.1907 106.302 34.7246 100.613 34.7246 92.9154C34.7246 84.5489 42.5334 77.2979 50.2306 77.2979C58.2625 77.2979 60.2704 82.262 59.9358 84.8278C59.8242 85.776 59.3222 86.5569 57.5931 86.5569H38.5174C38.2385 87.8397 38.0712 89.2899 38.0712 90.8517C38.0712 97.7122 44.0393 103.067 51.5692 103.067C58.374 103.067 62.5015 98.8836 65.5692 92.2461C65.8481 91.5768 66.2385 91.7441 65.9597 92.3577C63.0035 99.2182 57.1469 106.302 48.7246 106.302ZM52.3501 85.8875C53.131 85.8875 54.4138 85.5529 54.5812 85.3855C54.9158 84.9951 55.0832 84.3258 55.0832 83.6007C55.0832 81.4812 52.9636 78.525 48.6688 78.525C43.872 78.525 40.0792 81.0907 38.629 86.1106C44.9318 86.1106 48.7246 85.8875 52.3501 85.8875Z"
        fill="white"
      />
      <path
        d="M71.9779 106.358C67.5157 106.358 65.0616 103.513 65.0616 100.111C65.0616 96.3736 68.6871 92.6923 79.0058 88.6764L83.6353 86.8915C83.4122 79.9194 81.6273 77.7999 77.6671 77.7999C72.7588 77.7999 71.699 82.2063 70.4719 83.9911C69.9699 84.7162 69.4679 84.9951 67.962 84.9951C66.6233 84.9951 65.7309 84.4931 65.7309 83.7122C65.7309 83.0429 66.2887 81.9832 67.6831 81.0907C69.9142 79.6405 74.5994 77.2979 78.5596 77.2979C84.0257 77.2979 87.9859 80.1425 87.9859 87.4493C87.9859 91.1863 87.5397 97.4891 87.5397 99.6644C87.5397 102.844 87.9301 104.405 89.1014 104.405C90.1054 104.405 91.4998 102.453 92.1134 101.338C92.3922 100.892 93.0616 101.338 92.7827 101.784C91.8903 103.067 88.3763 106.302 86.201 106.302C84.0815 106.302 83.0217 104.852 83.0217 102.342C83.0217 100.892 83.1333 98.9393 83.2448 97.768C81.7389 100.334 75.8823 106.358 71.9779 106.358ZM68.9659 98.4931C68.9659 101.505 72.0337 103.903 74.7667 103.903C77.8903 103.903 82.8544 98.3258 83.3564 96.4851C83.4122 95.7601 83.691 93.027 83.691 88.4533V87.282L79.1173 89.2342C70.9181 92.6923 68.9659 94.3656 68.9659 98.4931Z"
        fill="white"
      />
      <path
        d="M117.083 79.9194V67.76C117.083 62.9632 116.191 62.9632 113.402 63.4652C112.956 63.521 112.956 63.019 113.402 62.9632C116.191 62.4612 118.254 61.4573 120.151 60.3975C120.318 60.2859 121.266 60.5091 121.266 60.7879V99.1067C121.266 104.573 121.322 105.242 124.111 105.242C124.557 105.242 124.557 105.744 124.111 105.744C120.709 105.744 119.203 105.911 117.362 106.302C116.916 106.413 116.693 106.19 116.693 105.8C116.693 105.465 117.027 103.234 117.083 100.055C114.406 103.959 109.999 106.302 105.649 106.302C99.2903 106.302 93.9357 100.892 93.9357 93.1943C93.9357 83.9911 101.912 77.2979 109.944 77.2979C112.9 77.2979 115.354 78.5808 117.083 79.9194ZM107.601 78.525C101.577 78.525 97.8401 82.764 97.8401 90.9632C97.8401 97.4891 101.577 104.461 108.493 104.461C111.896 104.461 115.187 102.286 117.083 99.1624V84.0469C115.075 81.9274 111.673 78.525 107.601 78.525Z"
        fill="white"
      />
      <path
        d="M0.7251 139.443C0.278884 139.443 0.278884 138.941 0.7251 138.941C3.23506 138.941 3.34661 138.773 3.34661 132.805V118.415C3.34661 116.797 2.06374 115.347 0.780876 114.176C0.613546 114.008 0.669323 113.897 0.780876 113.841C1.78486 113.451 3.90438 112.447 4.85259 111.498C4.96414 111.387 5.01992 111.387 5.18725 111.554C6.19124 112.558 7.36255 114.789 7.41833 117.132C10.1514 113.283 13.0518 110.996 17.3466 110.996C20.6932 110.996 24.3187 112.391 25.8247 117.299C28.6693 113.395 31.5697 110.996 35.9203 110.996C40.7729 110.996 45.2351 113.562 45.2351 122.319V136.654C45.2351 138.773 45.3466 138.996 48.0797 138.941C48.5259 138.941 48.5259 139.443 48.0797 139.443C47.6335 139.443 43.8406 139.331 42.9482 139.331C42.1116 139.331 38.8207 139.443 37.2032 139.443C36.757 139.443 36.757 138.941 37.2032 138.941C40.996 138.941 41.0518 138.829 41.0518 132.917V121.761C41.0518 114.845 37.4821 112.67 34.0797 112.67C29.9522 112.67 28.1116 115.012 26.0478 117.857V136.654C26.0478 138.773 26.6614 138.996 29.3944 138.941C29.8406 138.941 29.8406 139.443 29.3944 139.443C28.9482 139.443 24.9323 139.331 24.0398 139.331C23.2032 139.331 20.0797 139.443 18.4622 139.443C18.0159 139.443 18.0159 138.941 18.4622 138.941C21.9203 138.941 21.8645 138.829 21.8645 132.917V121.761C21.8645 114.845 18.9084 112.67 15.506 112.67C11.992 112.67 9.98406 114.231 7.4741 117.745V138.941H10.7649C11.2112 138.941 11.2112 139.443 10.7649 139.443C10.3187 139.443 7.08366 139.331 6.19124 139.331C5.35458 139.331 2.34263 139.443 0.7251 139.443Z"
        fill="white"
      />
      <path
        d="M64.7918 140C56.9273 140 50.9592 134.479 50.9592 126.056C50.9592 118.024 57.0946 110.996 65.6843 110.996C73.5488 110.996 79.4054 116.518 79.4054 125.108C79.4054 132.303 73.2141 140 64.7918 140ZM65.74 139.387C71.6524 139.387 74.8874 134.255 74.8874 126C74.8874 119.028 71.1504 111.554 65.0149 111.554C58.489 111.554 55.5886 116.965 55.5886 125.108C55.5886 131.969 59.2699 139.387 65.74 139.387Z"
        fill="white"
      />
      <path
        d="M93.3526 111.945C94.3009 111.331 95.4164 110.996 96.5319 110.996C97.8706 110.996 99.0419 111.275 99.0419 112C99.0419 112.893 98.2052 114.901 96.253 114.901H94.1893C90.1734 114.901 89.7829 115.459 89.7829 120.255V138.941H93.1853C93.6315 138.941 93.6315 139.443 93.1853 139.443C92.7391 139.443 89.1136 139.331 88.2212 139.331C87.3845 139.331 84.1495 139.443 82.5319 139.443C82.0857 139.443 82.0857 138.941 82.5319 138.941C85.265 138.941 85.6554 138.606 85.6554 132.805V118.415C85.6554 116.797 84.3726 115.347 83.0897 114.176C82.9224 114.008 82.9781 113.897 83.0897 113.841C84.0937 113.451 85.9901 112.447 86.9383 111.498C87.0499 111.387 87.1056 111.387 87.273 111.554C88.2212 112.447 89.4483 114.51 89.6714 116.686C90.3407 114.231 91.8467 112.949 93.3526 111.945Z"
        fill="white"
      />
      <path
        d="M112.726 140C104.193 140 98.7264 134.311 98.7264 126.614C98.7264 118.247 106.535 110.996 114.232 110.996C122.264 110.996 124.272 115.961 123.938 118.526C123.826 119.475 123.324 120.255 121.595 120.255H102.519C102.24 121.538 102.073 122.988 102.073 124.55C102.073 131.411 108.041 136.765 115.571 136.765C122.376 136.765 126.503 132.582 129.571 125.945C129.85 125.275 130.24 125.443 129.961 126.056C127.005 132.917 121.149 140 112.726 140ZM116.352 119.586C117.133 119.586 118.416 119.251 118.583 119.084C118.918 118.694 119.085 118.024 119.085 117.299C119.085 115.18 116.965 112.224 112.671 112.224C107.874 112.224 104.081 114.789 102.631 119.809C108.934 119.809 112.726 119.586 116.352 119.586Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.501 0.605251C101.963 3.18152 86.8301 18.6916 84.146 27.617C83.402 30.0913 82.8847 30.4783 77.3877 32.6748C69.2614 35.9223 59.3976 41.1805 47.8066 48.4436C42.4559 51.7968 38.2186 54.2994 38.3905 54.0057C44.088 44.2698 48.4175 36.063 48.1994 35.4114C48.0451 34.9494 44.4033 39.8075 40.1075 46.2077C35.811 52.6086 31.5708 58.7501 30.6841 59.8571C29.1596 61.7593 29.1405 61.9378 30.3293 63.1221C31.5181 64.3064 32.2746 64.2598 44.1948 62.2775C51.1294 61.1238 57.3799 59.8892 58.0837 59.5336C59.8585 58.6372 59.6829 57.5943 57.5994 56.6483C56.2292 56.0266 54.0813 56.1986 47.9939 57.4193C43.6813 58.2844 40.0688 58.8973 39.9656 58.7829C39.8625 58.6678 44.7611 55.472 50.8514 51.6809C61.8389 44.8412 76.9941 36.8537 80.66 35.9704C82.4187 35.5462 82.606 35.7306 83.0201 38.2967C83.6112 41.9567 87.0496 46.0036 91.3695 48.1229C94.3112 49.5667 95.9689 49.8283 102.161 49.8283C108.886 49.8283 109.879 49.6388 114.461 47.4765C122.53 43.6693 125.923 40.2637 125.932 35.9617C125.943 31.2275 121.354 28.0842 112.633 26.8525C106.859 26.0363 97.1599 26.7855 90.2561 28.579C86.4607 29.5658 86.4065 29.5571 86.863 28.0441C88.0935 23.9695 92.393 17.9089 98.0832 12.2294C105.63 4.69667 109.761 2.14591 115.347 1.57162C117.964 1.3027 119.106 0.928105 118.49 0.538932C117.308 -0.207347 113.079 -0.172365 110.501 0.605251ZM114.967 31.3127C117.021 31.7515 119.588 32.8803 120.673 33.8212C122.556 35.4551 122.595 35.6235 121.529 37.5788C118.667 42.8225 108.615 46.943 100.024 46.3928C93.6293 45.9839 89.7805 44.237 87.4095 40.6682C84.9573 36.9769 85.2887 34.1062 88.2611 33.2848C97.413 30.7545 108.515 29.9346 114.967 31.3127Z"
        fill="white"
      />
    </svg>
  )
}
