import cx from 'classnames'
import s from './modal-background.module.css'
import { useModal } from '@/hooks/use-modal'

type Props = {
  children?: React.ReactNode
}

export const ModalBackground = (props: Props) => {
  const { children } = props
  const modal = useModal()

  return (
    <div
      className={cx(s.background, {
        [s.backgroundVisible]: modal.isOpen,
      })}
    >
      {children}
    </div>
  )
}
