import { Image } from './Image'

export type Recycle = {
  recycles_images: {
    image: {
      id: number
      url: string
      alt: string
    }
  }[]
  name: string
  description: string
  price: number
  id: number
}

export type RecycleAttributeOption = {
  id: number
  attribute_id: number
  name: string
  sort_order: number
  created_at: string
  updated_at: string
}

export type RecycleAttribute = {
  id: number
  category_id: number
  name: string
  sort_order: number
  created_at: string
  updated_at: string
  options: RecycleAttributeOption[]
}

export type RecycleCategory = {
  id: number
  name: string
  images_id: number
  image: Image
  created_at: string
  updated_at: string
  attribute: RecycleAttribute[]
  slug?: string
}

export enum RecycleStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  SOLD = 'SOLD',
}
