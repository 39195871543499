import { SOCIAL_LINKS, PROD_URL, ROUTES } from '@/constants'
import Link from 'next/link'
import s from './footer.module.css'
import { Image } from '@/v2/components/atoms/image/image'

const socials = [
  {
    name: 'Facebook',
    link: SOCIAL_LINKS.FACEBOOK,
    image: `${PROD_URL}/assets/images/social/facebookIcon.png`,
  },
  {
    name: 'Instagram',
    link: SOCIAL_LINKS.INSTAGRAM,
    image: `${PROD_URL}/assets/images/social/instagramIcon.png`,
  },
  {
    name: 'Twitter',
    link: SOCIAL_LINKS.TWITTER,
    image: `${PROD_URL}/assets/images/social/twitterIcon.png`,
  },
  {
    name: 'Pinterest',
    link: SOCIAL_LINKS.PINTEREST,
    image: `${PROD_URL}/assets/images/social/pinterestIcon.png`,
  },
]

const links = [
  { name: 'Work with us', link: ROUTES.WORK_WITH_US },
  {
    name: 'Submissions',
    link: 'https://docs.google.com/forms/d/1mzNnEEfcJI-Nl1odd7je8VvCYSoW6IzcC1Z5EetfNsQ/',
  },
  { name: 'Affiliates', link: ROUTES.AFFILIATES },
  { name: 'About us', link: ROUTES.ABOUT_US },
  { name: 'Contact us', link: ROUTES.CONTACT_US },
  { name: 'Terms', link: ROUTES.TERMS },
  { name: 'Privacy', link: ROUTES.PRIVACY },
]

export const Footer = () => {
  return (
    <footer className={s.container}>
      <Image
        className={s.logo}
        width={118}
        height={40}
        alt="Rock my wedding logo"
        src={`${PROD_URL}/assets/images/RockMyWeddingLogo.png`}
      />
      <div className={s.socialContainer}>
        {socials.map((social) => (
          <a
            key={social.name}
            href={social.link}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              className={s.socialIcon}
              src={social.image}
              alt={social.name}
              width={30}
              height={30}
            />
          </a>
        ))}
      </div>
      <div className={s.divider} />
      <nav className={s.linkContainer}>
        {links.map((link) => (
          <Link key={link.name} href={link.link} className={s.link}>
            {link.name}
          </Link>
        ))}
      </nav>
    </footer>
  )
}
