import s from './dropdown-down-icon-2.module.css'
import cx from 'classnames'

type Props = React.SVGAttributes<SVGElement>

export const DropdownDownIcon2 = (props: Props) => {
  const { className, ...restProps } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 8"
      focusable="false"
      data-icon="down"
      width="12"
      height="8"
      fill="currentColor"
      aria-hidden="true"
      className={cx(s.icon, className)}
      {...restProps}
    >
      <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" fill="black" />
    </svg>
  )
}
