import s from './hamburger-icon.module.css'
import cx from 'classnames'

type Props = React.SVGAttributes<SVGElement>

export const HamburgerIcon = (props: Props) => {
  const { className, ...restProps } = props

  return (
    <svg
      viewBox="0 0 512 512"
      aria-hidden="true"
      focusable="false"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(s.icon, className)}
      {...restProps}
    >
      <path d="M64 384h384v-42.67H64zm0-106.67h384v-42.66H64zM64 128v42.67h384V128z"></path>
    </svg>
  )
}
