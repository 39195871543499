'use client'

import s from './sidebar.module.css'
import cx from 'classnames'

type Props = {
  isOpen: boolean
  onOverlayClick: () => void
  children: React.ReactNode
  width?: string | number
}

export const Sidebar = (props: Props) => {
  const { isOpen, onOverlayClick, children, width } = props

  return (
    <>
      <div
        className={cx(s.wrapper, {
          [s.open]: isOpen,
        })}
        style={{ maxWidth: width }}
      >
        {children}
      </div>
      <div
        className={cx(s.overlay, {
          [s.openOverlay]: isOpen,
        })}
        onClick={onOverlayClick}
      />
    </>
  )
}
