import Link from 'next/link'
import cx from 'classnames'
import s from './nav-button.module.css'

type Props = {
  children: React.ReactNode
  type?: 'primary' | 'secondary'
  href?: string
  elementProps?:
    | React.HTMLAttributes<HTMLButtonElement>
    | React.HTMLAttributes<HTMLAnchorElement>
}

export const NavButton = ({
  children,
  type = 'primary',
  href,
  elementProps,
}: Props) => {
  const buttonType = type === 'secondary' ? s.secondary : s.primary

  if (href) {
    return (
      <Link
        href={href}
        {...(elementProps as React.HTMLAttributes<HTMLAnchorElement>)}
        className={cx(s.button, buttonType)}
      >
        {children}
      </Link>
    )
  }

  return (
    <button
      {...(elementProps as React.HTMLAttributes<HTMLButtonElement>)}
      className={cx(s.button, buttonType)}
    >
      {children}
    </button>
  )
}
