'use client'

import DividerAnt from 'antd/lib/divider'
import styled, { css } from 'styled-components'
import breakpoints from '../../utils/breakpoints'
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import UserOutlined from '@ant-design/icons/UserOutlined'

export const UserStatusBar = styled.div`
  display: flex;
  height: 50px;
  background-color: black;
  color: white;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: -50px;
  left: 0;

  ${breakpoints('position', ';', [{ 1200: `unset` }, { 800: `unset` }])}
`

export const LoginIcon = styled(DownloadOutlined)`
  padding: 0.3rem;
  font-size: 16px;
  transform: rotate(-90deg);
`

export const UserIcon = styled(UserOutlined)`
  padding: 0.3rem;
  font-size: 16px;
`

export const LoginButton = styled.div`
  display: block;
  height: fit-content;
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 0 0.5rem;
  cursor: pointer;
`

export const LoginDisplay = styled.div`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    display: block;
    height: fit-content;
    padding: 1rem;
    cursor: pointer;
    color: #F8F8F8;
  `}
`

export const SignUpformWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 0 1rem;
  height: 100%;
  margin: 1.5rem auto 0;
`

export const SocialButtonWrapper = styled.div`
  text-align: center;
  display: block;
`

export const InputGroup = styled.div`
  width: 100%;
  margin: 0 auto 2rem;
`

export const Divider = styled(DividerAnt)`
  ${({}) => css`
    border-color: ${'#000000'} !important;
    .ant-divider-inner-text {
      font-family: Oxygen;
      font-size: 12px;
      font-weight: 700;
      margin: 1.5rem 2rem;
    }
  `}
`

export const MentionWrapper = styled.div`
  display: flex;
  ${breakpoints('margin', ';', [{ 1200: `2rem ` }, { 800: `2rem ` }])}
  align-items: center;
`

export const SignUpButton = styled.div`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    display: flex;
    position: relative;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    background-color: ${'#000000'};
    margin: 0 auto 2rem;
    width: 100%;
    height: 50px;
    color: #F8F8F8;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: ${'#484848'};
    }

    &:active {
      background-color: #C0C0C0;
    }
  `}
`

export default SignUpformWrapper
