'use client'

import { useModal } from '@/hooks/use-modal'
import { InputWrapper } from '../input-wrapper/input-wrapper'
import { Input } from '../../atoms/input/input'
import s from './banner-card-modal.module.css'
import { ErrorCode } from '@/errors/errorCode'
import { useRef, useState } from 'react'
import { api } from '@/api'
import { Image } from '../../atoms/image/image'
import { isEmpty } from 'lodash'
import { SpinAnimation } from '../../atoms/spin-animation/spin-animation'
import { PROD_URL } from '@/constants'
import { Image as ImageType } from '@/types/Image'
import { Tab } from '@headlessui/react'
import { TabButton } from '../../atoms/tab/tab-button'
import ImageTable from '../imageTable/ImageTable'
import { BannerCardPayload } from '@/types/api/article-category'
import { toast } from 'sonner'

export const BannerCardModal = () => {
  const modal = useModal()
  const bannerCard = modal.options.modalProps?.bannerCard
  const errorMessages = modal.options?.modalProps?.errorMessages
  const [isUploading, setIsUploading] = useState(false)
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const [imageAlt, setImageAlt] = useState(
    bannerCard?.imageAlt || bannerCard?.image?.alt || ''
  )
  const [imageUrl, setImageUrl] = useState(
    bannerCard?.imageUrl || bannerCard?.image?.url || ''
  )
  const [image, setImage] = useState<ImageType | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [bannerCardData, setBannerCardData] = useState<
    Partial<BannerCardPayload> | undefined
  >(bannerCard)

  const onSaveButtonClick = async () => {
    if (isImageUploaded && imageAlt) {
      const [err] = await api.fe.admin.updateImage({
        id: image?.id || bannerCard.imageId,
        alt: imageAlt,
      })

      setBannerCardData((bc) => ({
        ...bc,
        imageAlt,
      }))

      if (err) {
        toast.error(`Err code: ${ErrorCode.e00053} - image update failed`)
        console.log(ErrorCode.e00053, err)
      }
    }

    modal?.options?.modalProps?.onSave(bannerCardData)
    modal.close()
  }

  const setupFileData = async (files: FileList) => {
    if (!files) return
    setIsUploading(true)
    const fileArray = Array.from(files).map((file) => ({
      file,
    }))

    const [err, uploadedImages] = await api.fe.uploads.uploadImagesToS3({
      files: fileArray,
      dataType: 'article',
    })

    if (err) {
      toast.error(`Err code: ${ErrorCode.e00052} - image upload failed`)
      console.log(ErrorCode.e00052, err)
      setIsUploading(false)
      return
    }

    if (uploadedImages[0]?.url) {
      setImage(uploadedImages[0])
      setImageUrl(uploadedImages[0].url)
      setBannerCardData((bc) => ({
        ...bc,
        imageId: uploadedImages[0].id,
        imageUrl: uploadedImages[0].url,
        imageAlt: undefined,
      }))
      setImageAlt('')
      setIsImageUploaded(true)
    }
    setIsUploading(false)
  }

  const handleButtonClick = () => {
    if (fileInputRef) fileInputRef?.current?.click()
  }

  const handleImageChange = (image: ImageType) => {
    if (image.url !== imageUrl) {
      setIsUploading(true)
      setImageUrl(image.url)
      setBannerCardData((bc) => ({
        ...bc,
        imageId: image.id,
        imageUrl: image.url,
        imageAlt: image.alt,
      }))
    }
    setIsImageUploaded(false)
  }

  const handleOnLoadImage = () => {
    setIsUploading(false)
  }

  return (
    <div className={s.modalWrapper}>
      <h4>Banner Cards</h4>
      <InputWrapper
        className={s.inputWrapper}
        label="Title:"
        errorMessage={errorMessages?.title}
      >
        <Input
          value={bannerCardData?.title}
          onChange={(e) =>
            setBannerCardData((bc) => ({ ...bc, title: e.target.value }))
          }
        />
      </InputWrapper>
      <InputWrapper label="Image: " errorMessage={errorMessages?.imageId}>
        <div>
          {isUploading && <SpinAnimation />}
          <>
            <Image
              src={
                !isEmpty(imageUrl)
                  ? imageUrl
                  : `${PROD_URL}/assets/images/placeholder.jpeg`
              }
              width={100}
              fit
              height={100}
              alt="banner card image"
              loading="eager"
              onLoad={handleOnLoadImage}
              priority
            />
            <p>{imageUrl}</p>
          </>
          <Tab.Group>
            <Tab.List>
              <TabButton>Upload</TabButton>
              <TabButton>Library assets</TabButton>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className={s.uploadWrapper}>
                  <InputWrapper label="Image alt:">
                    <Input
                      disabled={!isImageUploaded}
                      value={imageAlt}
                      onChange={(e) => {
                        setImageAlt(e.target.value)
                        setBannerCardData((bc) => ({
                          ...bc,
                          imageAlt: e.target.value,
                        }))
                      }}
                    />
                  </InputWrapper>
                  <input
                    ref={fileInputRef}
                    hidden={true}
                    onChange={(e) => setupFileData(e.target.files!)}
                    type="file"
                  ></input>
                  <button
                    className={s.uploadButton}
                    type="button"
                    onClick={handleButtonClick}
                  >
                    Upload image
                  </button>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <ImageTable handleImageChange={handleImageChange} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </InputWrapper>
      <InputWrapper
        label="Button - caption:"
        errorMessage={errorMessages?.buttonText}
      >
        <Input
          value={bannerCardData?.buttonText}
          onChange={(e) =>
            setBannerCardData((bc) => ({ ...bc, buttonText: e.target.value }))
          }
        />
      </InputWrapper>
      <InputWrapper
        label="Button - link to page:"
        errorMessage={errorMessages?.buttonLink}
      >
        <Input
          placeholder="Add link 'https://'"
          value={bannerCardData?.buttonLink}
          onChange={(e) =>
            setBannerCardData((bc) => ({ ...bc, buttonLink: e.target.value }))
          }
        />
      </InputWrapper>
      <div className={s.buttonWrapper}>
        <button className={s.button} onClick={onSaveButtonClick}>
          Save
        </button>
        <button className={s.button} onClick={() => modal.close()}>
          Close
        </button>
      </div>
    </div>
  )
}
