'use client'

import { HamburgerIcon } from '@/v2/svg/hamburger-icon/hamburger-icon'
import s from './mobile-menu.module.css'
import { useState } from 'react'
import { Sidebar } from '../sidebar/sidebar'
import { MENU_ITEMS } from '@/constants/menu-items'
import Link from 'next/link'
import { Close as CloseIcon } from '@styled-icons/material/Close'
import { LoggedUserMenu } from '../logged-user-right-slider/logged-user-menu'
import { useUser } from '@/hooks/use-user'
// TODO: replace with icon not from antd
import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { useRouter } from 'next/navigation'
import { ROUTES } from '@/constants'
import cx from 'classnames'
import { UserIcon } from '@/components/auth/auth.style'

export const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { logOut, user } = useUser()
  const router = useRouter()

  const closeSidebar = () => {
    setIsOpen(false)
  }

  const openSiebar = () => {
    setIsOpen(true)
  }

  const onLogOut = async () => {
    setIsOpen(false)
    await logOut()
  }

  const onLogIn = async () => {
    setIsOpen(false)
    router.push(ROUTES.SIGN_IN)
  }

  const onSignUp = async () => {
    setIsOpen(false)
    router.push(ROUTES.SIGN_UP)
  }

  return (
    <>
      <HamburgerIcon onClick={openSiebar} className={s.hamburgerIcon} />
      <Sidebar width={'70%'} onOverlayClick={closeSidebar} isOpen={isOpen}>
        <div className={s.closeIconWrapper}>
          <CloseIcon className={s.closeIcon} onClick={closeSidebar} />
        </div>
        <ul className={s.navList}>
          {MENU_ITEMS.map((item) => (
            <li key={item.name} onClick={closeSidebar}>
              <Link className={s.navLinkItem} href={item.linkto}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
        <div className={s.bottomSection}>
          <LoggedUserMenu buttonClassName={s.loggedUserButton} />
          {user ? (
            <button onClick={onLogOut} className={s.menuBottomButton}>
              <DownloadOutlined height={26} className={s.logoutIcon} />
              <span className={'s.logoutText'}>log out</span>
            </button>
          ) : (
            <>
              <button onClick={onLogIn} className={s.menuBottomButton}>
                <DownloadOutlined height={26} className={s.logoutIcon} />
                <span className={'s.logoutText'}>log in</span>
              </button>
              <button
                onClick={onSignUp}
                className={cx(s.menuBottomButton, s.signUpButton)}
              >
                <UserIcon />
                <span className={'s.logoutText'}>sign up</span>
              </button>
            </>
          )}
        </div>
      </Sidebar>
    </>
  )
}
