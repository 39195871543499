import { Tab } from '@headlessui/react'
import { Fragment, ReactNode } from 'react'
import s from './tab-button.module.css'
import cx from 'classnames'

export const TabButton = ({ children }: { children: ReactNode }) => {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button
          type="button"
          className={cx(s.tab, {
            [s.tabSelected]: selected,
          })}
        >
          {children}
        </button>
      )}
    </Tab>
  )
}
