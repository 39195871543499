'use client'

import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { SectionContainer } from '@/v2/components/atoms/section-container/section-container'
import { Footer as OldFooter } from '@/v2/components/organisms/navigation/footer/footer'
import { Footer } from '@/v2/components/organisms/navigation/v2/footer/footer'

export const FooterIndex = () => {
  const ff = useFeatureFlag()

  if (ff.isEnabled('navBar')) {
    return <Footer />
  }

  return (
    <SectionContainer>
      <OldFooter />
    </SectionContainer>
  )
}
