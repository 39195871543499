type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const SearchIconNew = ({ iconColor, svgProps }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M5.30932 11.4304C6.49654 11.4315 7.64935 11.0014 8.58168 10.2096L12.1002 14L13 13.0307L9.48146 9.24033C10.2217 8.22275 10.6198 6.9647 10.6107 5.67209C10.6016 4.37931 10.1859 3.12803 9.4315 2.12252C8.67707 1.11707 7.62855 0.416873 6.45694 0.136272C5.28541 -0.144348 4.06014 0.0111758 2.98138 0.577492C1.90262 1.14381 1.03377 2.08751 0.517023 3.25434C0.000295212 4.42121 -0.133984 5.74239 0.136184 7.00204C0.406352 8.26163 1.06503 9.3855 2.00457 10.1898C2.94414 10.9941 4.10904 11.4316 5.30912 11.4307L5.30932 11.4304ZM5.30932 1.37148C6.37869 1.37148 7.40424 1.82908 8.16043 2.64372C8.91667 3.4583 9.34143 4.5631 9.34143 5.71511C9.34143 6.86712 8.91665 7.97188 8.16043 8.7865C7.40427 9.60116 6.37871 10.0587 5.30932 10.0587C4.23992 10.0587 3.21439 9.60114 2.4582 8.7865C1.70197 7.97192 1.2772 6.86712 1.2772 5.71511C1.2772 4.5631 1.70198 3.45834 2.4582 2.64372C3.21436 1.82906 4.23992 1.37148 5.30932 1.37148Z"
        fill={iconColor || '#131313'}
      />
    </svg>
  )
}
