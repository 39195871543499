import { useWindowSize } from 'usehooks-ts'

export type Breakpoint =
  | 'desktop-l'
  | 'desktop-m'
  | 'desktop-s'
  | 'tablet'
  | 'mobile'
  | null

export const useBreakpoint = (): Breakpoint => {
  const { width } = useWindowSize()

  if (!width) return null

  if (width >= 1440) {
    return 'desktop-l'
  }
  if (width >= 1280) {
    return 'desktop-m'
  }
  if (width >= 1024) {
    return 'desktop-s'
  }
  if (width >= 768) {
    return 'tablet'
  }

  return 'mobile'
}
