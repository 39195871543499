import s from './input-wrapper.module.css'

type Props = {
  label: string
  labelId?: string
  children: React.ReactNode
  errorMessage?: string
} & React.InputHTMLAttributes<HTMLLabelElement>

export const InputWrapper = ({
  label,
  errorMessage,
  children,
  labelId,
}: Props) => {
  return (
    <div className={s.wrapper}>
      {label && (
        <label htmlFor={labelId} className={s.label}>
          {label}
        </label>
      )}
      {children}
      {errorMessage && <div className={s.error}>{errorMessage}</div>}
    </div>
  )
}
