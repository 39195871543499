import { ROUTES, SOCIAL_LINKS } from '@/constants'
import { FacebookIcon } from '@/v2/svg/facebook-icon-new/facebook-icon'
import { InstagramIcon } from '@/v2/svg/instagram-icon/instagram-icon'
import { PinterestIcon } from '@/v2/svg/pinterest-icon/pinterest-icon'
import { RMWLogo } from '@/v2/svg/rmw-logo/rmw-logo'
import s from './footer.module.css'
import Link from 'next/link'

const socials = [
  {
    name: 'Instagram',
    link: SOCIAL_LINKS.INSTAGRAM,
    icon: InstagramIcon,
  },
  {
    name: 'Facebook',
    link: SOCIAL_LINKS.FACEBOOK,
    icon: FacebookIcon,
  },
  {
    name: 'Pinterest',
    link: SOCIAL_LINKS.PINTEREST,
    icon: PinterestIcon,
  },
]

const links = [
  { name: 'About us', link: ROUTES.ABOUT_US },
  { name: 'Affiliates', link: ROUTES.AFFILIATES },
  {
    name: 'Submissions',
    link: 'https://docs.google.com/forms/d/1mzNnEEfcJI-Nl1odd7je8VvCYSoW6IzcC1Z5EetfNsQ/',
  },
  { name: 'Terms & Conditions', link: ROUTES.TERMS },
  { name: 'Privacy Policy', link: ROUTES.PRIVACY },
  { name: 'Contact us', link: ROUTES.CONTACT_US },
  { name: 'Work with us', link: ROUTES.WORK_WITH_US },
]

export const Footer = () => {
  return (
    <footer className={s.container}>
      <RMWLogo />
      <div className={s.socialIcons}>
        {socials.map((social) => {
          const Icon = social.icon

          if (!Icon) return null

          return (
            <Link
              key={social.name}
              href={social.link}
              target="_blank"
              rel="noreferrer"
              aria-label={social.name}
            >
              <Icon />
            </Link>
          )
        })}
      </div>
      <nav className={s.links}>
        {links.map((link) => (
          <Link key={link.name} href={link.link} className={s.link}>
            {link.name}
          </Link>
        ))}
      </nav>
    </footer>
  )
}
