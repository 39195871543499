import s from './clear-circle-icon-2.module.css'
import cx from 'classnames'

type Props = React.SVGAttributes<SVGElement>

export const ClearCircleIcon2 = (props: Props) => {
  const { className, ...restProps } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      focusable="false"
      data-icon="close-circle"
      fill="currentColor"
      aria-hidden="true"
      className={cx(s.icon, className)}
      {...restProps}
    >
      <path
        d="M12 1.36421L10.6358 0L6 4.63579L1.36421 0L0 1.36421L4.63579 6L0 10.6358L1.36421 12L6 7.36421L10.6358 12L12 10.6358L7.36421 6L12 1.36421Z"
        fill="black"
      />
    </svg>
  )
}
