import { useModal } from '@/hooks/use-modal'
import s from './real-weddings-modal.module.css'
import { Button } from '@/v2/components/atoms/button-v3/button'
import { useScrollLock } from 'usehooks-ts'
import { RealWeddingsFilters } from '../real-weddings-filters/real-weddings-filters'
import { XIconNew } from '@/v2/svg/x-icon-new/x-icon-new'
import { FiltersIconNew } from '@/v2/svg/filters-icon-new/filters-icon-new'

export const RealWeddingsModal = () => {
  const modal = useModal()
  useScrollLock()

  const handleClose = () => {
    modal.close()
  }

  return (
    <div className={s.container}>
      <button onClick={handleClose} className={s.xButton}>
        <XIconNew
          svgProps={{
            width: 24,
            height: 24,
          }}
        />
      </button>
      <div className={s.header}>
        <span className={s.title}>Real Weddings</span>
        <span className={s.info}>
          Filters
          <FiltersIconNew iconColor="#000" />
        </span>
        <RealWeddingsFilters
          tags={modal.options.modalProps.realWeddingsFilters}
        />
      </div>
      {/* filters are applied on filter select */}
      <Button
        onClick={handleClose}
        variant="secondary"
        style={{
          marginTop: '57px',
          height: '68px',
        }}
      >
        Apply Filters
      </Button>
    </div>
  )
}
