'use client'

import Link from 'next/link'
import { Image } from '../../atoms/image/image'
import { PROD_URL } from '../../../../constants'
import { useUser } from '../../../../hooks/use-user'
import s from './message-icon.module.css'
import { useMessages } from '../../../../hooks/use-messages'

export const MessageIcon = () => {
  const { totalUnreadMessages } = useMessages()
  const { isSupplier } = useUser()

  const messageIconLink = isSupplier
    ? '/supplier/dashboard/messages?tab=all-message'
    : '/customer/dashboard/messages?tab=all-message'

  return (
    <div className={s.wrapper}>
      {!!totalUnreadMessages && <div className={s.dot} />}
      <Link href={messageIconLink}>
        <Image
          className={s.messageIcon}
          width={50}
          height={50}
          src={`${PROD_URL}/assets/icons/mail.png`}
          noOptimize
          alt={'message icon'}
        />
      </Link>
    </div>
  )
}
