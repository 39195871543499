export enum MessageTypes {
  COLLABORATION = 'collaboration',
  BROCHURE = 'brochure',
  ENQUIRY = 'enquiry',
  ARCHIVED = 'archived',
  RECYCLE = 'recycle',
}

export enum MessageSortKeys {
  DESC = 'desc',
  ASC = 'asc',
  UNREAD = 'unread',
  /** Descoping */
  RESPONDED = 'responded',
}

export interface Chatroom {
  id: number
  creator_id: number
  creator_supplier_id?: number | null
  participant_id: number
  participant_supplier_id?: number | null
  participant_recycle_id?: number | null
  type: string
  wedding_date?: Date | null
  is_creator_from_public: boolean
  chatroom_status: string
  created_at: Date
  updated_at: Date
  unread_count: number
}
