'use client'

import { useState } from 'react'
import { SocialAuthButton } from '../../molecules/social-auth-button/social-auth-button'

import s from './login-modal.module.css'
import cx from 'classnames'
import { useFormik } from 'formik'
import { useUser } from '@/hooks/use-user'
import { Input } from '../../atoms/input/input'
import { Button } from '../../atoms/button/button'
import { useModal } from '@/hooks/use-modal'
import { XModalButton } from '../../atoms/x-modal-button/x-modal-button'
import { useScrollLock } from 'usehooks-ts'
import { useRouter } from 'next/navigation'
import { useRegisterModal } from '@/hooks/use-register-modal'

type FormikValuesType = {
  email: string
  password: string
}

export const LoginModal = () => {
  const user = useUser()
  const [errorMsg, setErrorMsg] = useState('')
  const modal = useModal()
  const registerModal = useRegisterModal()
  const router = useRouter()
  useScrollLock()
  const redirectUrl = modal.options.modalProps.redirectUrl

  const onSubmit = async (values: FormikValuesType) => {
    setErrorMsg('')
    const [err] = await user.logIn(values.email, values.password)
    if (err) {
      setErrorMsg(err.message)

      return
    }

    await modal.close()
    if (redirectUrl) {
      router.push(redirectUrl)
    }
  }

  const formik = useFormik<FormikValuesType>({
    onSubmit,
    initialValues: {
      email: modal.options.modalProps.email || '',
      password: '',
    },
  })

  const onXButtonClick = () => {
    modal.close()
  }

  const onSignupClick = async () => {
    await modal.close()
    await registerModal.open()
  }

  return (
    <div className={cx(s.wrapper)}>
      <div className={cx(s.background)}>
        <XModalButton onClick={onXButtonClick} className={cx(s.xButton)} />
        <h1 className={cx(s.header)}>
          Log in to
          <br />
          Rock My Wedding
        </h1>
        <SocialAuthButton
          type="facebook"
          redirectUrl={redirectUrl || window.location.href}
        />
        <SocialAuthButton
          type="google"
          redirectUrl={redirectUrl || window.location.href}
        />
        <div className={cx(s.orContainer)}>
          <div className={cx(s.line)}></div>
          <span className={cx(s.orText)}>OR</span>
          <div className={cx(s.line)}></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <label className={s.label} htmlFor="email">
            * Your email address
          </label>
          <Input
            id="email"
            name="email"
            type="email"
            required
            onChange={formik.handleChange}
            value={formik.values.email}
            className={s.input}
          />
          <label htmlFor="password" className={s.label}>
            * Password
          </label>
          <Input
            type="password"
            id="password"
            name="password"
            pattern="(?=.*\d)(?=.*[a-z]).{8,}"
            required
            onChange={formik.handleChange}
            value={formik.values.password}
            className={s.input}
          />
          {errorMsg && <span className={s.error}>{errorMsg}</span>}
          <span className={s.inputDescription}>
            Enter a combination of at least 8 numbers and letters.
          </span>
          <br />
          <Button
            className={s.submitButton}
            type="submit"
            black
            disabled={formik.isSubmitting}
          >
            LOG IN
          </Button>
        </form>
      </div>
      <div className={s.footer}>
        New to Rock My Wedding? <button onClick={onSignupClick}>Sign up</button>
      </div>
    </div>
  )
}
