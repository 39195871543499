'use client'

import Link, { LinkProps } from 'next/link'
import s from './button.module.css'
import cx from 'classnames'

interface IButtonProps {
  children: React.ReactNode
  disabled?: boolean
  href?: string
  className?: string
  variant?: 'primary' | 'secondary'
}

export const Button = ({
  children,
  href,
  className,
  variant = 'primary',
  ...props
}: IButtonProps & (React.ComponentProps<'button'> | LinkProps)) => {
  const getButtonType = () => {
    switch (variant) {
      case 'primary':
        return s.primary
      case 'secondary':
        return s.secondary
      default:
        return s.primary
    }
  }

  const buttonType = getButtonType()

  return (
    <>
      {href ? (
        <Link
          {...(props as LinkProps)}
          href={href}
          aria-disabled={props.disabled}
          tabIndex={props.disabled ? -1 : undefined}
          className={cx(s.button, buttonType, className)}
        >
          {children}
        </Link>
      ) : (
        <button
          {...(props as React.ComponentProps<'button'>)}
          disabled={props.disabled}
          className={cx(s.button, buttonType, className)}
        >
          {children}
        </button>
      )}
    </>
  )
}
