type Props = React.SVGAttributes<SVGElement>

export const FiltersIcon = (props: Props) => {
  const { className, ...restProps } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      focusable="false"
      fill="none"
      aria-hidden="true"
      className={className}
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2796 10.7674V12.0698H14.6111V13.6977H9.2796V15H8.00003V10.7674H9.2796ZM7.14698 12.0698V13.6977H1.38892V12.0698H7.14698ZM5.22763 5.88372V10.1163H3.94806V8.81395H1.38892V7.18605H3.94806V5.88372H5.22763ZM14.6111 7.18605V8.81395H6.08067V7.18605H14.6111ZM12.052 1V2.30233H14.6111V3.93023H12.052V5.23256H10.7724V1H12.052ZM9.91938 2.30233V3.93023H1.38892V2.30233H9.91938Z"
        fill="black"
      />
    </svg>
  )
}
