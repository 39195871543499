'use client'

import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { Header } from '@/v2/components/organisms/navigation/v2/header/header'
import { V2TopMenu } from '@/v2/components/organisms/navigation/top-menu/top-menu'
import { TopNavBar } from '@/v2/components/organisms/navigation/top-nav-bar/top-nav-bar'
import { MainNavMobile } from '@/v2/components/organisms/navigation/v2/main-nav-mobile/main-nav-mobile'
import { useState } from 'react'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { MainNav } from '@/v2/components/organisms/navigation/v2/main-nav/main-nav'
import { Article } from '@/types/Article'
import {
  UiPlacement,
  UiPlacementPlacement,
  UiPlacementResponse,
  UiPlacementType,
} from '@/types/api/ui-placement'
import { Supplier } from '@/types/Supplier'

export type MenuItemsType = {
  mainItem: UiPlacement
  subcategories: UiPlacement[]
}[]

function groupMenuItems(data: UiPlacementResponse['data']): MenuItemsType {
  const main = [
    ...data[UiPlacementType.ARTICLE_CATEGORY]?.[UiPlacementPlacement.MAIN_MENU],
    ...(data.metadata ? data.metadata[UiPlacementPlacement.MAIN_MENU] : []),
  ]
  const child = [
    ...data[UiPlacementType.ARTICLE_SUBCATEGORY]?.[
      UiPlacementPlacement.SUB_MENU
    ],
    ...data[UiPlacementType.BUSINESS_CATEGORY]?.[UiPlacementPlacement.SUB_MENU],
  ]

  const combinedItems = main
    .map((item) => {
      const subItems = child
        .filter((subItem: UiPlacement) => {
          // this one is hardcoded because we don't have a way to connect these values on the be
          const isWeddingSuppliers = item.metadata?.slug === 'wedding-suppliers'

          if (isWeddingSuppliers) {
            return subItem.type === UiPlacementType.BUSINESS_CATEGORY
          }

          const isChildOfMainCategory =
            subItem.article_subcategories?.parent_slug ===
              item.article_category_id ||
            subItem.metadata?.overrides?.article_category_id ===
              item.article_category_id

          return isChildOfMainCategory
        })
        .toSorted((a, b) => a.sort_order - b.sort_order)

      return {
        mainItem: item,
        subcategories: subItems,
      }
    })
    .toSorted((a, b) => a.mainItem.sort_order - b.mainItem.sort_order)

  return combinedItems
}

type Props = {
  featuredArticles?: Article[]
  menuItems: UiPlacementResponse['data']
  navbarSuppliers?: Supplier[]
  navbarVenues?: Supplier[]
}

export const NavigationIndex = ({
  featuredArticles,
  menuItems,
  navbarSuppliers,
  navbarVenues,
}: Props) => {
  const ff = useFeatureFlag()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const breakpoint = useBreakpoint()
  const isDesktop = breakpoint?.includes('desktop')
  const groupedMenuItems = groupMenuItems(menuItems)

  if (ff.isEnabled('navBar')) {
    return (
      <>
        <Header onHamburgerIconClick={() => setIsMenuOpen(true)} />
        {isDesktop ? (
          <MainNav
            featuredArticles={featuredArticles}
            navbarSuppliers={navbarSuppliers}
            navbarVenues={navbarVenues}
            menuItems={groupedMenuItems}
          />
        ) : (
          <MainNavMobile
            isMenuOpen={isMenuOpen}
            onOutsideNavClick={() => setIsMenuOpen(false)}
            menuItems={groupedMenuItems}
          />
        )}
      </>
    )
  }
  return (
    <>
      <V2TopMenu />
      <TopNavBar />
    </>
  )
}
