type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const UserIcon = ({ svgProps, iconColor }: Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.60698 8.05958H6.39275C4.79533 8.05958 3.5 9.28059 3.5 10.7863V12.2455C3.49936 12.5849 3.70001 12.8963 4.01943 13.0526L4.02007 13.0532C6.62104 14.3106 9.26905 14.3216 11.9672 13.0502H11.9678C12.2924 12.8957 12.4974 12.5824 12.4974 12.2401C12.5 11.9013 12.5 11.359 12.5 10.7864C12.5 9.28049 11.2047 8.05959 9.60725 8.05959L9.60698 8.05958ZM7.99979 2C6.40302 2 5.10703 3.22159 5.10703 4.72676C5.10703 6.23191 6.40299 7.45352 7.99979 7.45352C9.59667 7.45352 10.8925 6.23193 10.8925 4.72676C10.8925 3.22151 9.59659 2 7.99979 2Z"
        fill={iconColor || '#131313'}
      />
    </svg>
  )
}
