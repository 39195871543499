type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const BurgerIcon = ({ iconColor, svgProps }: Props) => {
  return (
    <svg
      width="24"
      height="36"
      viewBox="0 0 24 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <line
        y1="5.25"
        x2="24"
        y2="5.25"
        stroke={iconColor || '#000'}
        strokeWidth="1.5"
      />
      <line
        y1="17.25"
        x2="24"
        y2="17.25"
        stroke={iconColor || '#000'}
        strokeWidth="1.5"
      />
      <line
        y1="29.25"
        x2="24"
        y2="29.25"
        stroke={iconColor || '#000'}
        strokeWidth="1.5"
      />
    </svg>
  )
}
