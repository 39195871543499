type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const RMWLogo = ({ iconColor, svgProps }: Props) => {
  return (
    <svg
      width="95"
      height="32"
      viewBox="0 0 95 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M9.86981 23.4372C9.7574 23.0866 9.65234 22.7582 9.54729 22.4298C9.53093 22.4284 9.51458 22.427 9.49822 22.4256C8.51579 25.4897 7.53336 28.5537 6.54518 31.6357C5.70153 31.6357 4.85794 31.6357 3.98203 31.6357C2.65965 27.2064 1.3329 22.7624 0 18.2978C1.09247 18.2978 2.15097 18.2978 3.2315 18.2978C3.9432 21.1371 4.65495 23.9766 5.36675 26.8163C5.38446 26.8115 5.39372 26.81 5.4021 26.8064C5.41066 26.8028 5.42349 26.7988 5.4257 26.7921C6.36041 23.9646 7.29437 21.1368 8.23191 18.2981C9.14633 18.2981 10.0583 18.2981 10.9895 18.2981C11.9177 21.2195 12.8449 24.1378 13.7721 27.0562C13.7905 27.0557 13.8089 27.0551 13.8272 27.0546C14.5773 24.1384 15.3273 21.2222 16.0795 18.2978C17.0754 18.2978 18.0573 18.2978 19.0825 18.2978C17.7736 22.7465 16.4679 27.1843 15.1574 31.6384C14.2851 31.6384 13.4113 31.6384 12.5014 31.6384C11.6299 28.9223 10.7535 26.1908 9.86981 23.4372Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M74.8608 29.5851C73.3295 27.105 71.8103 24.6425 70.2911 22.18C70.2736 22.1857 70.2561 22.1913 70.2385 22.197C70.2385 25.3366 70.2385 28.4761 70.2385 31.6356C69.2454 31.6356 68.2766 31.6356 67.287 31.6356C67.287 27.2019 67.287 22.7629 67.287 18.3047C68.6103 18.3047 69.9411 18.3047 71.304 18.3047C73.1931 21.3715 75.0951 24.4592 76.9971 27.547C77.016 27.541 77.035 27.535 77.0539 27.529C77.0539 24.4572 77.0539 21.3854 77.0539 18.2996C78.0511 18.2996 79.021 18.2996 80.0083 18.2996C80.0083 22.7452 80.0083 27.1842 80.0083 31.6393C78.719 31.6393 77.4363 31.6393 76.1294 31.6393C75.7162 30.9696 75.2945 30.2861 74.8608 29.5851Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M58.1845 28.9352C57.2287 30.1463 55.9807 30.876 54.5338 31.2967C53.7449 31.5261 52.9381 31.6512 52.1146 31.6525C50.4967 31.6551 48.8789 31.6548 47.2611 31.6552C47.2037 31.6552 47.1462 31.6504 47.0708 31.6471C47.0708 27.2 47.0708 22.7608 47.0708 18.3036C47.1337 18.2977 47.1986 18.2862 47.2635 18.2862C48.7644 18.2857 50.2654 18.2724 51.7661 18.2913C53.0385 18.3073 54.2936 18.4758 55.4885 18.9325C57.5287 19.7123 58.8398 21.1479 59.2948 23.2883C59.5765 24.6138 59.5569 25.9426 59.1136 27.2431C58.9055 27.8538 58.5872 28.4059 58.1845 28.9352ZM54.3273 21.5354C53.5842 21.1877 52.7963 21.0314 51.9804 21.0141C51.396 21.0017 50.8111 21.0101 50.2265 21.0113C50.1621 21.0114 50.0978 21.0249 50.0382 21.0317C50.0382 23.6707 50.0382 26.2912 50.0382 28.9249C50.7021 28.9249 51.3533 28.9482 52.0019 28.9173C52.4857 28.8941 52.9742 28.8364 53.4467 28.7318C54.6802 28.4589 55.6236 27.7932 56.0923 26.5915C56.4459 25.6846 56.4949 24.7392 56.2528 23.7955C55.9862 22.7563 55.3201 22.0312 54.3273 21.5354Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M32.5469 24.5578C32.5469 22.459 32.5469 20.3892 32.5469 18.3026C32.64 18.296 32.7148 18.2861 32.7895 18.2861C34.2606 18.2856 35.7318 18.2746 37.2027 18.2903C38.4948 18.304 39.7689 18.4693 40.9821 18.9328C43.033 19.7164 44.3458 21.1625 44.795 23.3143C45.0779 24.6699 45.0648 26.0336 44.5678 27.3481C43.9391 29.0107 42.7502 30.16 41.1387 30.8906C40.0899 31.366 38.9826 31.6266 37.8325 31.6434C36.1182 31.6684 34.4033 31.6534 32.6887 31.6547C32.6514 31.6548 32.6142 31.6471 32.5469 31.6398C32.5469 29.2846 32.5469 26.9357 32.5469 24.5578ZM41.8695 25.1906C41.8744 25.1036 41.8822 25.0167 41.8836 24.9297C41.9111 23.267 41.0597 22.0064 39.5007 21.3993C38.8875 21.1605 38.2486 21.037 37.5934 21.0187C36.9413 21.0006 36.2883 21.0118 35.6358 21.0119C35.6001 21.0119 35.5643 21.0274 35.5399 21.0331C35.5399 23.675 35.5399 26.3017 35.5399 28.9242C36.2141 28.9242 36.8675 28.9472 37.5184 28.9166C37.9924 28.8943 38.4705 28.8352 38.9335 28.7332C40.1082 28.4746 41.0203 27.8551 41.5292 26.7339C41.7439 26.2608 41.8426 25.76 41.8695 25.1906Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M85.7899 31.2211C83.6856 30.1486 82.5282 28.4325 82.1978 26.1537C82.0062 24.8318 82.0922 23.5222 82.577 22.2649C83.4323 20.047 85.0811 18.7278 87.3742 18.1708C88.4849 17.9009 89.6121 17.8958 90.7432 18.0251C91.4807 18.1094 92.2047 18.2521 92.8988 18.5193C93.5768 18.7804 94.1818 19.1585 94.7353 19.6672C94.0364 20.4249 93.3472 21.1721 92.6542 21.9233C92.6097 21.8879 92.5776 21.8667 92.5507 21.8403C91.7943 21.099 90.8753 20.735 89.8236 20.6776C89.187 20.6429 88.5587 20.6804 87.9485 20.8843C86.8442 21.2534 86.0378 21.9579 85.5868 23.026C85.0368 24.3284 85.0214 25.6556 85.598 26.9468C86.2458 28.3975 87.4294 29.1368 89.0041 29.2626C89.9713 29.3398 90.907 29.1869 91.7982 28.7945C91.8782 28.7592 91.9576 28.7225 92.0364 28.6845C92.0711 28.6678 92.1033 28.6462 92.1503 28.619C92.1503 27.9106 92.1503 27.1988 92.1503 26.4568C91.3607 26.4568 90.5765 26.4568 89.7722 26.4568C89.7722 25.543 89.7722 24.6587 89.7722 23.753C91.5064 23.753 93.2444 23.753 95 23.753C95 26.0677 95 28.3868 95 30.7221C94.4234 31.0325 93.8115 31.2792 93.1773 31.4673C91.7454 31.8923 90.279 32.058 88.7885 31.9823C87.7481 31.9294 86.7511 31.6819 85.7899 31.2211Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M67.7457 0.798813C69.1346 4.08778 70.5154 7.35604 71.8963 10.6243C71.9205 10.6248 71.9448 10.6252 71.969 10.6257C73.3991 7.21242 74.8291 3.79913 76.2647 0.372671C77.1805 0.372671 78.0917 0.372671 79.0186 0.372671C79.0186 4.88134 79.0186 9.37994 79.0186 13.8997C78.4196 13.8997 77.8205 13.8997 77.1785 13.8997C77.172 12.6855 77.1765 11.4605 77.1757 10.2355C77.1749 8.9959 77.1755 7.75627 77.1755 6.51664C77.1755 5.288 77.1755 4.05937 77.1755 2.83073C77.1577 2.82536 77.1399 2.81998 77.122 2.8146C75.5941 6.50998 74.0663 10.2054 72.535 13.9089C72.1227 13.9089 71.7162 13.9089 71.2934 13.9089C69.7646 10.2117 68.2361 6.51502 66.7076 2.81833C66.6908 2.82247 66.674 2.8266 66.6572 2.83074C66.6572 6.51336 66.6572 10.196 66.6572 13.8997C66.0385 13.8997 65.4392 13.8997 64.8178 13.8997C64.8178 9.40161 64.8178 4.89571 64.8178 0.372501C65.7251 0.372501 66.6278 0.372501 67.5589 0.372501C67.6145 0.498744 67.6761 0.638425 67.7457 0.798813Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M20.6095 22.4658C20.6095 21.0637 20.6095 19.6906 20.6095 18.3011C23.6541 18.3011 26.6799 18.3011 29.7268 18.3011C29.7268 19.1901 29.7268 20.0835 29.7268 21.0055C27.6878 21.0055 25.6471 21.0055 23.5911 21.0055C23.5911 21.8428 23.5911 22.6517 23.5911 23.4888C25.5155 23.4888 27.4387 23.4888 29.3833 23.4888C29.3833 24.4094 29.3833 25.3022 29.3833 26.2235C27.4535 26.2235 25.5293 26.2235 23.5904 26.2235C23.5904 27.1366 23.5904 28.0213 23.5904 28.9307C25.751 28.9307 27.8999 28.9307 30.071 28.9307C30.071 29.8471 30.071 30.739 30.071 31.6435C26.9234 31.6435 23.7812 31.6435 20.6095 31.6435C20.6095 28.5875 20.6095 25.5412 20.6095 22.4658Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M24.7874 1.52662C26.3851 2.86619 27.2115 4.56838 27.3501 6.60909C27.4587 8.20871 27.1324 9.71095 26.2675 11.0742C25.1652 12.8118 23.5675 13.8215 21.5384 14.1586C20.1765 14.3848 18.8348 14.2849 17.5468 13.7763C15.3093 12.8928 13.9214 11.2492 13.3616 8.9433C12.8756 6.94159 13.1354 5.01874 14.2236 3.24316C15.211 1.63198 16.6567 0.623596 18.4995 0.210878C20.2731 -0.186346 22.0049 -0.0397593 23.6401 0.798982C24.0355 1.00182 24.3953 1.27337 24.7874 1.52662ZM24.1759 10.746C24.46 10.3988 24.6967 10.0221 24.8809 9.61366C25.3768 8.51393 25.5163 7.36426 25.3332 6.17928C25.1621 5.07245 24.7306 4.07594 23.9369 3.265C22.7812 2.08423 21.3589 1.6024 19.7197 1.76669C17.8893 1.95015 16.5509 2.87943 15.717 4.49791C15.1108 5.67452 14.9833 6.93609 15.2069 8.22987C15.389 9.28315 15.83 10.2175 16.572 11.0047C18.5423 13.0949 22.2125 13.0892 24.1759 10.746Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M10.0899 2.24611C10.7214 3.70229 10.7294 5.09178 9.72189 6.37103C9.12756 7.12568 8.32383 7.55772 7.36881 7.70546C7.32178 7.71274 7.27446 7.71816 7.18331 7.73031C7.59322 8.44388 8.03588 9.10932 8.45639 9.7877C8.88024 10.4714 9.30948 11.1519 9.73635 11.8338C10.162 12.5137 10.5877 13.1937 11.0317 13.903C10.2513 13.903 9.50746 13.903 8.72002 13.903C7.57899 11.9394 6.42175 9.94804 5.26226 7.95276C4.57917 7.95276 3.90051 7.95276 3.20051 7.95276C3.20051 9.93702 3.20051 11.9088 3.20051 13.9006C2.5803 13.9006 1.98089 13.9006 1.36026 13.9006C1.36026 9.40103 1.36026 4.89512 1.36026 0.368275C1.46045 0.363767 1.55513 0.355848 1.64981 0.355797C3.10197 0.355017 4.55413 0.35389 6.00629 0.355976C6.55235 0.35676 7.09869 0.371709 7.63242 0.501649C8.67607 0.755733 9.54351 1.26109 10.0899 2.24611ZM3.91947 6.32608C4.63952 6.32183 5.36009 6.33202 6.0794 6.30682C6.40678 6.29534 6.73961 6.24319 7.05619 6.15859C7.97509 5.91301 8.44265 5.37849 8.53647 4.44342C8.6681 3.13155 8.07928 2.29566 6.75688 2.0693C6.38463 2.00558 6.00354 1.97389 5.62567 1.96563C4.89616 1.94968 4.16605 1.96038 3.4362 1.96158C3.36157 1.9617 3.28696 1.97234 3.20827 1.97842C3.20827 3.43221 3.20827 4.8679 3.20827 6.32608C3.43647 6.32608 3.64889 6.32608 3.91947 6.32608Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M51.1734 2.503C51.857 1.8385 52.53 1.19245 53.1913 0.534782C53.3283 0.398488 53.4669 0.34977 53.6546 0.352246C54.3076 0.360853 54.9607 0.355576 55.6138 0.355579C55.6996 0.355579 55.7855 0.355579 55.8535 0.355579C55.8767 0.383276 55.8858 0.389447 55.8871 0.396971C55.8887 0.405706 55.8856 0.417074 55.8806 0.424736C55.8702 0.440585 55.8578 0.455248 55.8451 0.469447C55.8322 0.483921 55.8184 0.497649 55.8043 0.511025C53.6913 2.51921 51.5782 4.52733 49.4653 6.53558C49.4512 6.54892 49.4383 6.56358 49.426 6.57848C49.4199 6.58576 49.4159 6.59483 49.4117 6.60345C49.4075 6.6121 49.4042 6.6212 49.3961 6.64093C51.7167 9.04794 54.0406 11.4584 56.4145 13.9206C56.2799 13.9206 56.2075 13.9206 56.1351 13.9206C55.4236 13.9206 54.7119 13.9134 54.0006 13.9253C53.7981 13.9286 53.6608 13.8691 53.5223 13.7209C51.5261 11.5848 49.5239 9.45415 47.5215 7.32373C47.4469 7.24438 47.3557 7.18046 47.2407 7.12385C47.1734 7.88702 47.1977 8.63683 47.1932 9.3858C47.1888 10.1315 47.1923 10.8772 47.1923 11.623C47.1923 12.3775 47.1922 13.132 47.1922 13.9035C46.5754 13.9035 45.9767 13.9035 45.3625 13.9035C45.3625 9.39719 45.3625 4.8978 45.3625 0.376976C45.9594 0.376976 46.5571 0.376976 47.182 0.376976C47.182 2.31025 47.182 4.24267 47.182 6.15705C47.3177 6.25481 47.3874 6.18027 47.4578 6.11198C47.6599 5.91579 47.8614 5.71888 48.0635 5.52262C49.0951 4.52079 50.1269 3.51908 51.1734 2.503Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M31.877 2.21615C33.0097 0.986127 34.4073 0.308974 36.0376 0.0874009C37.3161 -0.0863488 38.5622 0.0357561 39.7693 0.505894C40.6477 0.84801 41.4018 1.39977 41.9426 2.12292C41.4363 2.50699 40.9292 2.89156 40.4082 3.28675C40.3685 3.23888 40.3387 3.20078 40.3066 3.16465C39.354 2.09137 38.1373 1.64125 36.7266 1.75571C34.502 1.93621 33.0708 3.16724 32.3545 5.22147C31.8309 6.72294 31.8679 8.23985 32.5456 9.69258C33.39 11.5023 34.8588 12.4273 36.855 12.5292C37.7806 12.5765 38.6575 12.4064 39.4484 11.896C39.8863 11.6135 40.2605 11.2643 40.5763 10.8521C40.6051 10.8145 40.6377 10.7798 40.6851 10.7245C41.2012 11.1105 41.71 11.491 42.2415 11.8885C41.949 12.2464 41.6583 12.5688 41.3112 12.8347C39.8817 13.9299 38.2643 14.3581 36.4671 14.2443C33.7995 14.0754 30.9779 12.119 30.2524 8.83429C29.7144 6.39821 30.2256 4.16724 31.877 2.21615Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M85.7997 3.72282C86.3391 4.58477 86.8664 5.4293 87.4116 6.3026C87.498 6.17635 87.5639 6.08475 87.6249 5.99003C88.78 4.19684 89.9371 2.40489 91.0852 0.607314C91.2019 0.424597 91.3188 0.341611 91.548 0.348624C92.1795 0.367955 92.8119 0.355305 93.4935 0.355305C93.4226 0.472086 93.374 0.559103 93.319 0.641946C91.7152 3.05743 90.112 5.47336 88.5036 7.8858C88.3911 8.05447 88.3376 8.21902 88.3385 8.42325C88.3454 10.1375 88.3424 11.8517 88.3424 13.566C88.3424 13.6714 88.3424 13.7767 88.3424 13.9014C87.7272 13.9014 87.1276 13.9014 86.4859 13.9014C86.4859 13.8048 86.4859 13.7023 86.4859 13.5997C86.486 11.8661 86.4834 10.1325 86.4896 8.39886C86.4903 8.20495 86.4392 8.04752 86.3326 7.88761C84.7296 5.4832 83.1311 3.07583 81.5316 0.66909C81.4738 0.582043 81.4171 0.494243 81.3422 0.379857C81.4413 0.369195 81.5055 0.356426 81.5697 0.356273C82.2033 0.354766 82.837 0.361565 83.4704 0.351196C83.6392 0.348432 83.7334 0.40616 83.8195 0.54566C84.4714 1.6014 85.1306 2.65271 85.7997 3.72282Z"
        fill={iconColor || '#fff'}
      />
      <path
        d="M61.5175 26.9404C61.5175 24.0484 61.5175 21.1854 61.5175 18.3037C62.5067 18.3037 63.4751 18.3037 64.4644 18.3037C64.4644 22.7422 64.4644 27.186 64.4644 31.643C63.4895 31.643 62.5198 31.643 61.5175 31.643C61.5175 30.08 61.5175 28.5247 61.5175 26.9404Z"
        fill={iconColor || '#fff'}
      />
    </svg>
  )
}
