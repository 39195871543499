import { Button } from '@/v2/components/atoms/button/button'
import cx from 'classnames'
import s from './social-auth-button.module.css'
import { API_URL, API_V1 } from '@/constants'
import { FacebookIcon } from '@/v2/svg/facebook-icon/facebook-icon'
import { GoogleIcon } from '@/v2/svg/google-icon/google-icon'

type Props = {
  type: 'facebook' | 'google'
  label?: string
  className?: string
  redirectUrl?: string
  errorMessage?: string
}

function renderIcon(type: Props['type']) {
  switch (type) {
    case 'facebook':
      return <FacebookIcon />
    case 'google':
      return <GoogleIcon />
    default:
      throw new Error('Incorrect social auth type provided!')
  }
}

function getAuthUrl(type: Props['type'], redirectUrl: string) {
  switch (type) {
    case 'facebook':
      const facebookUrl = new URL(`${API_V1}${API_URL.AUTH.FACEBOOK}`)
      facebookUrl.searchParams.append('redirectUrl', redirectUrl)
      return facebookUrl
    case 'google':
      const googleUrl = new URL(`${API_V1}${API_URL.AUTH.GOOGLE}`)
      googleUrl.searchParams.append('redirectUrl', redirectUrl)
      return googleUrl
    default:
      throw new Error('Incorrect social auth type provided!')
  }
}

export const SocialAuthButton = (props: Props) => {
  const { type, label, redirectUrl = '/', errorMessage } = props
  const url = getAuthUrl(type, redirectUrl)

  return (
    <>
      <Button className={cx(s.button)} href={url.toString()}>
        {renderIcon(type)}
        <div className={cx(s.buttonText)}>
          {label || `Continue with ${type}`}
        </div>
      </Button>
      {errorMessage && <div className={cx(s.errorMessage)}>{errorMessage}</div>}
    </>
  )
}
