type Props = React.SVGAttributes<SVGElement>

export const BookmarkIconSVG = (props: Props) => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.00553 11.7427L7.00529 11.7424C6.86409 11.6113 6.68272 11.5436 6.5 11.5436C6.31725 11.5436 6.13576 11.6113 5.99447 11.7427C5.99444 11.7428 5.99441 11.7428 5.99438 11.7428L0.500011 16.8522L0.500167 0.5H12.5V16.8522L7.00553 11.7427Z"
        stroke="#131313"
      />
    </svg>
  )
}
