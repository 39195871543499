import { FunctionComponent } from 'react'
import s from './card.module.css'
import { Image } from '../image/image'
import { BookmarkIcon } from '../../../icons/bookmark-icon/bookmark-icon'
import { FAVOURITE, SLIDER_CLASS_NAME } from '../../../../constants'
import Link from 'next/link'
import { ReadMoreSVG } from '../../../svg/read-more/read-more'
import cx from 'classnames'

export type CardSize = 'Small' | 'Medium' | 'Large'

export type CardType = 'Article' | 'Supplier' | 'Venue'

const getFavoriteType = (type: CardType) => {
  switch (type) {
    case 'Article':
      return FAVOURITE.ARTICLE
    case 'Supplier':
      return FAVOURITE.SUPPLIER
    case 'Venue':
      return FAVOURITE.VENUE
    default:
      throw new Error(`Unknown card type: ${type}`)
  }
}

type Props = {
  header: string
  date?: string
  category: string
  imageUrl: string
  imageAlt: string
  imageLoading?: 'lazy' | 'eager'
  itemId: number | string
  path: string
  type: CardType
  size?: CardSize
  adjustToSlider?: boolean
  reverseVertical?: boolean
  primaryColor?: string
  location?: string
}

const Card: FunctionComponent<Props> = ({
  header,
  date,
  category,
  location,
  imageUrl,
  imageAlt,
  imageLoading,
  itemId,
  type,
  path,
  size = 'Medium',
  adjustToSlider = false,
  reverseVertical = false,
  primaryColor = '#000',
}) => {
  const favType = getFavoriteType(type)

  let redirectPath = path
  if (type === 'Supplier') {
    redirectPath = `/wedding-suppliers/${path}`
  }

  if (type === 'Venue') {
    redirectPath = `/wedding-venues/${path}`
  }

  return (
    <div
      role="article"
      className={cx(
        { [SLIDER_CLASS_NAME]: adjustToSlider },
        s[`wrapper${size}`]
      )}
    >
      <div className={s.bookmark}>
        <BookmarkIcon itemId={itemId} type={favType} />
      </div>

      <Link href={redirectPath}>
        <div
          className={cx(s[`container${size}`], {
            [s.reverseVertical]: reverseVertical,
          })}
        >
          <div
            className={s.headerContainer}
            style={{
              color: primaryColor,
            }}
          >
            <h4
              className={s[`header${size}`]}
              style={{
                color: primaryColor,
              }}
            >
              {header}
            </h4>
            {type === 'Article' && (
              <p className={s.category}>
                <span>{category}</span> / {date}
              </p>
            )}
            {(type === 'Supplier' || type === 'Venue') && (
              <p className={s.supplierCategory}>
                {category}, <span>{location}</span>
              </p>
            )}
          </div>
          <div className={s[`imageContainer${size}`]}>
            <div className={s.shadowHover}>
              <ReadMoreSVG className={s[`readMore${size}`]} />
            </div>
            <Image
              alt={imageAlt}
              fill
              src={imageUrl}
              className={cx(s.image, s[`cardSize${size}`])}
              loading={imageLoading}
            />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Card
