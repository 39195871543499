import { css } from 'styled-components'

export const breakpoints: any = (
  cssProp = 'padding',
  cssPropUnits = 'px',
  values = [],
  mediaQueryType = 'min-width'
) => {
  const breakpointProps = values.reduce((mediaQueries, value) => {
    const [screenBreakpoint, cssPropBreakpoint] = [
      Object.keys(value)[0],
      Object.values(value)[0],
    ]
    return (mediaQueries += `
    @media only screen and (${mediaQueryType}: ${screenBreakpoint}px) {
      ${cssProp}: ${cssPropBreakpoint}${cssPropUnits};
    }
    `)
  }, '')
  // @ts-ignore
  return css([breakpointProps])
}

export const containerBreakpoint = (condition: string, context: string) => {
  return `
    @container (${condition}) {
      ${context}
    }
  `
}

export default breakpoints
