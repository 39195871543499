'use client'

import { ROUTES } from '@/constants'
import s from './logged-user-menu.module.css'
import cx from 'classnames'
import { useUser } from '@/hooks/use-user'
import { Image } from '@/v2/components/atoms/image/image'
import UserOutlined from '@ant-design/icons/UserOutlined'
import Link from 'next/link'

type Props = {
  buttonClassName?: string
}

export const LoggedUserMenu = (props: Props) => {
  const { buttonClassName } = props
  const { user, isSupplier, isAdmin, isReader } = useUser()

  if (!user) {
    return null
  }

  const getProfileLink = () => {
    if (isAdmin) {
      return ROUTES.ADMIN.ROOT
    }

    if (isSupplier) {
      return ROUTES.SUPPLIER.SUMMARY
    }

    if (isReader) {
      return ROUTES.CUSTOMER.DASHBOARD
    }

    return ''
  }

  const profileLink = getProfileLink()

  return (
    <Link
      href={profileLink}
      className={cx(s.topBarUserPictureWrapper, buttonClassName)}
    >
      <span className={s.buttonUserName}>
        {user.first_name + ' ' + user.last_name}
      </span>
      {user.image_profile ? (
        <div className={s.iconWrapper}>
          <Image
            className={s.profileImage}
            width={104}
            height={104}
            src={user.image_profile}
            alt={'profile image'}
          />
        </div>
      ) : (
        <div className={s.iconWrapper}>
          <UserOutlined />
        </div>
      )}
    </Link>
  )
}
