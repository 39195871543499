import { useModal } from '@/hooks/use-modal'
import s from './confirmation-modal.module.css'

export const ConfirmationModal = () => {
  const modal = useModal()

  const handleConfirm = () => {
    modal.options.modalProps.onConfirm?.()
    modal.close()
  }

  return (
    <div className={s.container}>
      {modal.options.modalProps.label}
      <div className={s.buttonsContainer}>
        <button className={s.button} onClick={handleConfirm}>
          Yes
        </button>
        <button className={s.button} onClick={() => modal.close()}>
          No
        </button>
      </div>
    </div>
  )
}
