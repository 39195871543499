'use client'

import Script from 'next/script'
import { useEffect } from 'react'

/**
 * Contents of <head> tag common for all pages
 * to be used in app and pages routers
 */
export const CommonHeaders = () => {
  useEffect(() => {
    const handleConsent = () => {
      const gtmScript = document.createElement('script')
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}`
      gtmScript.async = true
      document.head.appendChild(gtmScript)
    }

    // Listen for the Iubenda consent event
    window.addEventListener('iubenda_consent_given', handleConsent)
    return () =>
      window.removeEventListener('iubenda_consent_given', handleConsent)
  }, [])

  return (
    <>
      {/* MailerLite script */}
      <Script id="mailerlite" strategy="afterInteractive">
        {`(function(w,d,e,u,f,l,n){w[f]=w[f]||function(){(w[f].q=w[f].q||[])
          .push(arguments);},l=d.createElement(e),l.async=1,l.src=u,
          n=d.getElementsByTagName(e)[0],n.parentNode.insertBefore(l,n);})
          (window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
          ml('account', '${process.env.NEXT_PUBLIC_MAILERLITE_ACCOUNT}');`}
      </Script>

      {/* Cookie popup (Iubenda) */}
      <Script
        id="cookieBannerConfig"
        type="text/javascript"
        strategy="afterInteractive"
      >
        {`var _iub = _iub || [];
          _iub.csConfiguration = {
            "consentOnContinuedBrowsing": false,
            "whitelabel": false,
            "lang": "en",
            "siteId": 2069986,
            "cookiePolicyId": 28408035,
            "banner": {
              "acceptButtonDisplay": true,
              "customizeButtonDisplay": true,
              "acceptButtonColor": "#010101",
              "acceptButtonCaptionColor": "white",
              "customizeButtonColor": "#b3b3b3",
              "customizeButtonCaptionColor": "white",
              "rejectButtonColor": "#0073CE",
              "rejectButtonCaptionColor": "white",
              "position": "float-bottom-left",
              "textColor": "#010101",
              "backgroundColor": "#ffffff"
            }
          };`}
      </Script>
      <Script
        type="text/javascript"
        src="//cdn.iubenda.com/cs/iubenda_cs.js"
        strategy="afterInteractive"
        async
      />
    </>
  )
}
