'use client'

import cx from 'classnames'
import s from './x-modal-button.module.css'
import { XIcon } from '@/v2/svg/x-icon/x-icon'

type Props = React.HTMLAttributes<HTMLButtonElement>

export const XModalButton = (props: Props) => {
  const { className } = props

  return (
    <div className={className}>
      <button {...props} className={cx(s.x, className)}>
        <XIcon />
      </button>
    </div>
  )
}
