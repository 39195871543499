'use client'

import { Switch } from '@headlessui/react'
import cx from 'classnames'
import s from './checkbox.module.css'
import React from 'react'

type Props = {
  label: React.ReactNode
  checked: boolean
  onChange: (value: boolean) => void
  className?: string
  children?: React.ReactNode
}

export const Checkbox = ({
  label,
  checked = false,
  onChange,
  className,
  children,
}: Props) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      className={cx(s.switch, className)}
    >
      <span className={s.tick} />
      <span className={s.label}>{children || label}</span>
    </Switch>
  )
}
