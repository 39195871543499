'use client'

import { Fragment } from 'react'
import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import s from './select.module.css'
import cx from 'classnames'
import { DropdownButton } from '@/v2/components/atoms/dropdown-button/dropdown-button'
import { ArrowRightShort } from '@/v2/svg/arrow-right-short/arrow-right-short'
import { XIconNew } from '@/v2/svg/x-icon-new/x-icon-new'

type OptionItem = {
  value: number | string
  label: string
}

export interface SelectProps<T extends OptionItem> {
  label: string
  options: T[]
  onChange: (value: T['value']) => void
  onRemove: (value: T['value']) => void
  value: T['value']
  disabled?: boolean
  className?: string
}

export const Select = <T extends OptionItem>({
  label,
  options,
  onChange,
  onRemove,
  value,
  disabled,
  className,
}: SelectProps<T>) => {
  return (
    <div className={cx(s.wrapper, s.typography, className)}>
      <Listbox value={value} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <>
            <DropdownButton
              label={
                value ? (
                  <span
                    role="button"
                    className={cx(s.currentValue, s.typography)}
                    onClick={(e) => {
                      e.stopPropagation()
                      onRemove(value)
                    }}
                  >
                    {options.find((o) => o.value === value)?.label}
                    <XIconNew />
                  </span>
                ) : (
                  `${label}`
                )
              }
              className={cx(s.button, s.typography)}
              icon={
                <ArrowRightShort
                  svgProps={{
                    style: {
                      transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                      position: 'absolute',
                      right: '12px',
                    },
                  }}
                />
              }
            />
            <Transition
              as={Fragment}
              leave={s.transitionLeave}
              leaveFrom={s.opacity1}
              leaveTo={s.opacity0}
              enterFrom={s.opacity0}
              enterTo={s.opacity1}
            >
              <ListboxOptions className={s.options} static>
                {options.map((o) => {
                  return (
                    <ListboxOption
                      key={o.value}
                      value={o.value}
                      className={s.listboxOption}
                    >
                      {({ selected, active }) => {
                        return (
                          <div
                            className={cx(s.option, {
                              [s.selected]: selected,
                              [s.active]: active,
                            })}
                          >
                            <span>{o.label}</span>
                          </div>
                        )
                      }}
                    </ListboxOption>
                  )
                })}
              </ListboxOptions>
            </Transition>
          </>
        )}
      </Listbox>
    </div>
  )
}
