'use client'
import { Image } from '@/v2/components/atoms/image/image'
import s from './top-nav-bar.module.css'
import { SectionContainer } from '@/v2/components/atoms/section-container/section-container'
import { MENU_ITEMS } from '@/constants/menu-items'
import { SearchIcon } from '@/v2/svg/search-icon/search'
import Link from 'next/link'
import { PROD_URL, ROUTES } from '@/constants'
import { MobileMenu } from '@/v2/components/organisms/navigation/mobile-menu/mobile-menu'

export const TopNavBar = () => {
  return (
    <SectionContainer
      className={s.sectionContainerWrapper}
      innerProps={{
        className: s.sectionContainer,
      }}
    >
      <div className={s.container}>
        <Link href={ROUTES.HOME}>
          <Image
            src={`${PROD_URL}/assets/images/logo/logo-app.png`}
            alt="logo"
            width={110}
            height={110}
            fit
          />
        </Link>
        <ul className={s.navList}>
          {MENU_ITEMS.map((item) => (
            <li key={item.name}>
              <Link className={s.navLinkItem} href={item.linkto}>
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
        <Link
          className={s.searchIconWrapper}
          href={ROUTES.SEARCH}
          aria-label="Search"
        >
          <SearchIcon className={s.searchIcon} />
        </Link>
        <MobileMenu />
      </div>
    </SectionContainer>
  )
}
