import { Slider } from '@/v2/components/atoms/slider/slider'
import Card, { CardType } from '@/v2/components/atoms/card/card'
import s from './navigation-slider.module.css'
import { ArrowRight } from '@/v2/svg/arrow-right/arrow-right'

interface Props {
  onItemClick?: () => void
  items: CardItem[]
  cardType: CardType
}

export type CardItem = {
  id: number | string
  title: string
  date?: string
  imageUrl?: string
  imageAlt?: string
  category?: string
  location?: string
  path: string
}

export const NavigationSlider = ({ onItemClick, items, cardType }: Props) => {
  if (!items?.length) {
    return null
  }

  return (
    <div className={s.container}>
      <Slider
        options={{
          initial: 0,
          slides: {
            spacing: 24,
            perView: 'auto',
          },
          breakpoints: {},
        }}
        renderNavigation={(slideLeft, slideRight) => {
          const navigationVisible = items.length > 3
          return (
            <div className={s.topWrapper}>
              <span className={s.title}>Featured</span>
              {navigationVisible && (
                <div className={s.sliderControls}>
                  <button onClick={slideLeft} className={s.sliderArrow}>
                    <ArrowRight
                      iconColor="#fff"
                      svgProps={{
                        style: {
                          transform: 'rotate(180deg)',
                        },
                      }}
                    />
                  </button>
                  <button onClick={slideRight} className={s.sliderArrow}>
                    <ArrowRight iconColor="#fff" />
                  </button>
                </div>
              )}
            </div>
          )
        }}
      >
        {items?.map((item) => {
          return (
            <div key={`${item.id}-nav-slider`} onClick={() => onItemClick?.()}>
              <Card
                primaryColor="#fff"
                reverseVertical
                size="Small"
                adjustToSlider
                itemId={item.id!}
                header={item.title}
                path={item.path}
                date={item.date}
                category={item?.category || 'Category'}
                location={item?.location || 'Location'}
                type={cardType}
                imageUrl={item.imageUrl!}
                imageAlt={item.imageAlt!}
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
