type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const PinterestIcon = ({ iconColor, svgProps }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12.5 0C5.87259 0 0.5 5.37259 0.5 12C0.5 17.0837 3.66298 21.4297 8.12686 23.1781C8.022 22.2287 7.92718 20.7686 8.16847 19.7321C8.38655 18.7955 9.57561 13.7673 9.57561 13.7673C9.57561 13.7673 9.21667 13.0484 9.21667 11.9857C9.21667 10.3171 10.1838 9.07138 11.388 9.07138C12.4118 9.07138 12.9063 9.84002 12.9063 10.7617C12.9063 11.7913 12.2508 13.3307 11.9125 14.7572C11.6298 15.9515 12.5115 16.9256 13.6895 16.9256C15.8223 16.9256 17.4618 14.6767 17.4618 11.4305C17.4618 8.55731 15.3972 6.54847 12.4493 6.54847C9.03489 6.54847 7.03076 9.10945 7.03076 11.7561C7.03076 12.7875 7.42806 13.8934 7.92383 14.4946C8.0219 14.6135 8.03627 14.7175 8.00705 14.8388C7.91597 15.2178 7.71353 16.0329 7.67379 16.1997C7.62145 16.4194 7.49987 16.466 7.27245 16.3602C5.77373 15.6625 4.83669 13.4713 4.83669 11.7113C4.83669 7.926 7.58703 4.44962 12.7655 4.44962C16.9283 4.44962 20.1633 7.41596 20.1633 11.3802C20.1633 15.5159 17.5557 18.8442 13.9364 18.8442C12.7204 18.8442 11.5773 18.2125 11.186 17.4663C11.186 17.4663 10.5843 19.7574 10.4384 20.3189C10.1675 21.3612 9.43612 22.6677 8.94695 23.4648C10.0697 23.8124 11.2629 23.9999 12.5 23.9999C19.1274 23.9999 24.5 18.6273 24.5 12C24.5 5.37259 19.1274 0 12.5 0Z"
        fill={iconColor || '#fff'}
      />
    </svg>
  )
}
