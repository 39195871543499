'use client'

import { ReactNode, createContext, useCallback, useState } from 'react'
import { MessagesContextType } from './types'
import { api } from '../../api'
import { Chatroom, MessageSortKeys } from '../../types/api/chat-room'
import { ErrorCode } from '../../errors/errorCode'
import { useUser } from '../../hooks/use-user'
import useSWR, { Fetcher } from 'swr'
import { PaginationResponse } from '../../types/api/pagination-response'
import { toast } from 'sonner'

export const MessagesContext = createContext<MessagesContextType | undefined>(
  undefined
)

const fetcher: Fetcher<
  PaginationResponse<Chatroom> | undefined,
  string
> = async () => {
  const [err, messages] = await api.fe.chatRooms.search({
    sortKey: MessageSortKeys.UNREAD,
  })
  if (err) {
    throw new Error(err.message)
  }

  return messages
}

export const MessagesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0)
  const { user, isAdmin } = useUser()

  const { mutate } = useSWR(user && !isAdmin ? 'messages' : null, fetcher, {
    onSuccess: (messagesData) => {
      const totalUnreadCount = messagesData?.results.reduce(
        (acc, item) => acc + item.unread_count,
        0
      )
      setTotalUnreadMessages(totalUnreadCount || 0)
    },
    onError: (error) => {
      console.log(ErrorCode.e00027, error)
      toast.error(
        `Something went wrong while loading unread messages, ${ErrorCode.e00027}`
      )
    },
  })

  const refreshUnreadMessages = useCallback(
    async (errorCode: ErrorCode) => {
      try {
        if (user && !isAdmin) {
          mutate()
        }
      } catch (error) {
        console.log(errorCode, error)
        toast.error(
          `Something went wrong while loading unread messages, ${errorCode}`
        )
      }
    },
    [user]
  )

  return (
    <MessagesContext.Provider
      value={{
        totalUnreadMessages,
        refreshUnreadMessages,
      }}
    >
      {children}
    </MessagesContext.Provider>
  )
}
