'use client'

import s from './date-picker-button.module.css'
import cx from 'classnames'
import { format } from 'date-fns'
import { forwardRef } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

interface Props extends ReactDatePickerProps {
  buttonProps?: React.HTMLProps<HTMLButtonElement>
}

export const DatePickerButton = ({ ...props }: Props) => {
  const CustomInput = forwardRef<
    HTMLButtonElement,
    Omit<Props, 'onChange'> & { onClick?(): void }
  >(({ value, onClick }, ref) => (
    <button
      {...props.buttonProps}
      type="button"
      className={cx(s.input, props.buttonProps?.className)}
      onClick={onClick}
      ref={ref}
    >
      {value ? format(value, 'dd MMMM yyyy') : props.placeholderText}
    </button>
  ))
  CustomInput.displayName = 'CustomInput'

  return (
    <DatePicker
      selected={props.value ? new Date(String(props.value)) : null}
      onChange={props.onChange}
      customInput={<CustomInput />}
      placeholderText={props.placeholderText}
    />
  )
}
