import { useCallback, useEffect, useMemo, useState } from 'react'
import { ensureQueryArray } from '@/utils/ensureQueryArray'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import qs from 'qs'
import { Tag } from '@/types/Tag'

const getTagsByCategory = (tags: Tag[]) => {
  const tagsObject = tags.reduce(
    (acc, tag) => {
      acc[tag.category_label] = acc[tag.category_label]
        ? [...acc[tag.category_label], tag]
        : [tag]
      return acc
    },
    {} as { [key: string]: Tag[] }
  )

  const keysWithGlobalOrder = ['Budget']

  Object.entries(tagsObject).forEach(([key, value]) => {
    if (keysWithGlobalOrder.includes(key)) {
      tagsObject[key] = value.sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order
        }
        return 0
      })
    }
  })

  return tagsObject
}

type Props = {
  tagsToDisplay: Tag[]
}

export const useArticleFilters = ({ tagsToDisplay }: Props) => {
  const pathName = usePathname()
  const router = useRouter()
  const query = useSearchParams()
  const [tags, setTags] = useState<{ [key: string]: Tag[] }>({})
  const selectedTags = useMemo(() => {
    const queryParamTags = ensureQueryArray(query?.getAll('tag'))
    const selectedTags = tagsToDisplay.filter((tag) =>
      queryParamTags.includes(tag.id.toString())
    )
    return selectedTags
  }, [query, tagsToDisplay])

  useEffect(() => {
    setTags(getTagsByCategory(tagsToDisplay))
  }, [tagsToDisplay])

  const changeTagFilters = useCallback(
    (selectedTagId: number) => {
      const queryParamTags = ensureQueryArray(query?.getAll('tag'))
      let newTags: string[] = []

      // if tag is already selected, remove it
      if (queryParamTags.includes(selectedTagId.toString())) {
        newTags = queryParamTags.filter(
          (tagId) => tagId !== selectedTagId.toString()
        )
      }

      // if tag is not selected, check if it's from the same category
      // if it is, remove all tags from that category and add the new one
      // if it's not, add the new tag
      if (!queryParamTags.includes(selectedTagId.toString())) {
        const selectedTag = tagsToDisplay.find(
          (tag) => tag.id === Number(selectedTagId)
        )

        const selectedTagCategory = selectedTag?.category
        const tagsFromSameCategory = tagsToDisplay.filter(
          (tag) => tag.category === selectedTagCategory
        )

        const tagsFromSameCategoryIds = tagsFromSameCategory.map(
          (tag) => tag.id
        )

        const tagsFromSameCategorySelected = tagsFromSameCategoryIds.filter(
          (tagId) => queryParamTags.includes(tagId.toString())
        )

        if (tagsFromSameCategorySelected.length > 0) {
          newTags = queryParamTags.filter(
            (tagId) => !tagsFromSameCategoryIds.includes(Number(tagId))
          )
          newTags = [...newTags, selectedTagId.toString()]
        } else {
          newTags = [...queryParamTags, selectedTagId.toString()]
        }
      }

      // update url
      const search = query?.getAll('search')
      router.push(
        `${pathName}?${qs.stringify(
          { tag: newTags, search },
          { indices: false }
        )}`,
        { scroll: false }
      )
    },
    [tagsToDisplay, router, pathName, query]
  )

  const removeTagFilter = useCallback(
    (tagId: number) => {
      const queryParamTags = ensureQueryArray(query?.getAll('tag'))
      const newTags = queryParamTags.filter((tag) => tag !== tagId.toString())
      const search = query?.getAll('search')
      router.push(
        `${pathName}?${qs.stringify(
          { tag: newTags, search },
          { indices: false }
        )}`,
        { scroll: false }
      )
    },
    [router, pathName, query]
  )

  return { removeTagFilter, changeTagFilters, tags, selectedTags }
}
