import { GTM_EVENT } from '@/constants'
import { sendGTMEvent } from '@next/third-parties/google'

export const navItemOnClickGTMEvent = (clickedText?: string) => {
  if (!clickedText) return

  sendGTMEvent({
    event: GTM_EVENT.NAV_CLICK,
    click_text: clickedText,
  })
}
