type Props = {
  svgProps?: React.SVGProps<SVGSVGElement>
  iconColor?: string
}

export const FacebookIcon = ({ iconColor, svgProps }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M24.4999 12.0728C24.4999 5.40465 19.127 -0.000976562 12.4992 -0.000976562C5.87143 -0.000976562 0.498535 5.40465 0.498535 12.0728C0.498535 18.0992 4.88702 23.0942 10.6241 24V15.5629H7.57708V12.0728H10.6241V9.41282C10.6241 6.38682 12.4158 4.71535 15.157 4.71535C16.4699 4.71535 17.8433 4.95117 17.8433 4.95117V7.92246H16.33C14.8393 7.92246 14.3743 8.85316 14.3743 9.80798V12.0728H17.7027L17.1706 15.5629H14.3743V24C20.1115 23.0942 24.4999 18.0992 24.4999 12.0728Z"
        fill={iconColor || '#fff'}
      />
    </svg>
  )
}
