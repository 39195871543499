'use client'

import React, { useState, useRef, useMemo, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { ColDef } from 'ag-grid-community'
import { Image } from '../../atoms/image/image'
import { api } from '@/api'
import s from './imageTable.module.css'
import ReactPaginate from 'react-paginate'
import { Image as ImageType } from '@/types/Image'
import { InputSearch } from '@/components/ui/inputSearch'
import { GetImagesOptionsType } from '@/api/fetchers/admin.api'
import { ErrorCode } from '@/errors/errorCode'
import { toast } from 'sonner'

type Props = {
  handleImageChange: (image: ImageType) => void
}

const ImageTable = ({ handleImageChange }: Props) => {
  const gridRef = useRef<AgGridReact<ImageType>>(null)
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [params, setParams] = useState<GetImagesOptionsType>({
    page: 1,
    search: '',
  })
  const [totalPages, setTotalPages] = useState(0)
  const [rowData, setRowData] = useState<ImageType[]>([])
  const [columnDefs] = useState<ColDef<ImageType>[]>([
    {
      headerName: 'Thumbnail',
      field: 'url',
      cellRenderer: (params: { value: string }) => {
        return (
          <div className={s.imageWrapper}>
            <Image
              src={params.value}
              alt="image"
              width={100}
              height={100}
              fit
            />
          </div>
        )
      },
      sortable: false,
      filter: false,
      autoHeight: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      sortable: false,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: 'Alt',
      field: 'alt',
      sortable: false,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: 'URL',
      field: 'url',
      sortable: false,
      wrapText: true,
      cellClass: 'allow-text-selection',
      minWidth: 350,
      autoHeight: true,
      resizable: true,
    },
    {
      headerName: 'Id',
      field: 'id',
      sortable: false,
      hide: true,
    },
  ])

  useEffect(() => {
    const fetchImages = async () => {
      const [err, images] = await api.fe.admin.getImages({
        limit: 5,
        ...params,
      })

      if (err) {
        toast.error(`Err code: ${ErrorCode.e00051} - fetching image failed`)
        console.log(ErrorCode.e00051, err)
      }

      if (images) {
        const totalPage = Math.ceil(
          images.pagination.total / images.pagination.pageSize
        )

        setTotalPages(totalPage)
        setRowData(images?.results)
        setIsLoading(false)
      }
    }

    fetchImages()
  }, [params])

  const defaultColDef = useMemo<ColDef<ImageType>>(
    () => ({
      flex: 1,
      minWidth: 150,
      resizable: true,
    }),
    []
  )

  const onSearch = () => {
    setParams({
      page: 1,
      search,
    })
    setIsLoading(true)
  }

  return (
    <div className={s.wrapper}>
      <div className={s.inputWrapper}>
        <InputSearch
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          onSearchClick={onSearch}
          placeholder="Search..."
        />
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={false}
          domLayout="autoHeight"
          onRowClicked={(value) => handleImageChange(value?.data!)}
          suppressCellFocus
          enableCellTextSelection
          suppressPaginationPanel
          alwaysShowVerticalScroll
          alwaysShowHorizontalScroll
          rowHeight={100}
          rowStyle={{ cursor: 'pointer' }}
        />
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={(value) => {
          if (!isLoading)
            setParams((params) => ({ ...params, page: value.selected + 1 }))
        }}
        pageRangeDisplayed={5}
        pageCount={totalPages}
        previousLabel="<"
        pageClassName={s.pageItem}
        pageLinkClassName={s.pageLink}
        previousClassName={s.pageItem}
        previousLinkClassName={s.pageLink}
        nextClassName={s.pageItem}
        nextLinkClassName={s.pageLink}
        breakClassName={s.pageItem}
        breakLinkClassName={s.pageLink}
        containerClassName={s.container}
        activeClassName={s.active}
        renderOnZeroPageCount={() => null}
        forcePage={params.page! - 1}
      />
    </div>
  )
}

export default ImageTable
