'use client'

import { Image } from '@/v2/components/atoms/image/image'
import s from './header.module.css'
import Link from 'next/link'
import { PROD_URL, ROUTES } from '@/constants'
import { useBreakpoint } from '@/hooks/use-breakpoint'
import { useUser } from '@/hooks/use-user'
import { MessagesIcon } from '@/v2/svg/messages-icon/messages-icon'
import { UserIcon } from '@/v2/svg/user-icon/user-icon'
import { useMessages } from '@/hooks/use-messages'
import { NavButton } from '../nav-button/nav-button'
import { NavSearch } from '../nav-search/nav-search'
import { BurgerIcon } from '@/v2/svg/burger-icon/burger-icon'

type Props = {
  onHamburgerIconClick: () => void
}

export const Header = ({ onHamburgerIconClick }: Props) => {
  const breakpoint = useBreakpoint()
  const isNotMobile = breakpoint !== 'mobile'
  const isDesktop = breakpoint?.includes('desktop')

  const { isAdmin, isSupplier, isReader, user, logOut } = useUser()
  const { totalUnreadMessages } = useMessages()

  const messageIconLink = isSupplier
    ? '/supplier/dashboard/messages?tab=all-message'
    : '/customer/dashboard/messages?tab=all-message'

  const getProfileLink = () => {
    if (isAdmin) {
      return ROUTES.ADMIN.ROOT
    }

    if (isSupplier) {
      return ROUTES.SUPPLIER.SUMMARY
    }

    if (isReader) {
      return ROUTES.CUSTOMER.DASHBOARD
    }

    return ''
  }

  const profileLink = getProfileLink()

  return (
    <div className={s.container}>
      <div className={s.innerContainer}>
        <div className={s.leftWrapper}>
          {isDesktop ? (
            <NavSearch />
          ) : (
            <button
              className={s.burger}
              onClick={onHamburgerIconClick}
              aria-label="Open menu"
            >
              <BurgerIcon />
            </button>
          )}
        </div>

        <div className={s.centerWrapper}>
          <Link href={ROUTES.HOME}>
            <Image
              src={`${PROD_URL}/assets/images/logo/logo-app.png`}
              alt="Rock My Wedding"
              width={isDesktop ? 90 : 80}
              height={isDesktop ? 30 : 25}
              fit
            />
          </Link>
        </div>

        <div className={s.rightWrapper}>
          {user ? (
            <div className={s.userInfo}>
              {!isAdmin && (
                <NavButton href={messageIconLink}>
                  <MessagesIcon /> {isNotMobile && 'Messages'} (
                  {totalUnreadMessages} new)
                </NavButton>
              )}
              <NavButton type="secondary" href={profileLink}>
                <UserIcon
                  iconColor="#fff"
                  svgProps={{
                    'aria-label': 'User Profile',
                  }}
                />{' '}
                {isNotMobile && user?.first_name}
              </NavButton>
              {isDesktop && (
                <NavButton
                  elementProps={{
                    onClick: logOut,
                    style: { paddingRight: 0 },
                  }}
                >
                  Log out
                </NavButton>
              )}
            </div>
          ) : (
            <div className={s.userInfo}>
              <NavButton href={ROUTES.SIGN_IN}>
                <UserIcon /> Log in
              </NavButton>
              {isDesktop && (
                <NavButton type="secondary" href={ROUTES.SIGN_UP}>
                  Create Account
                </NavButton>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
